import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import RatioOverflowTableWrapper from "../../../components/styled-components/ratio-overflow-table-wrapper/RatioOverflowTableWrapper";

const EmailTemplatesGrid = (props) => {

  const generateRow = (row) => {
    return (
      <TableRow key={row.id}>
        <TableRowColumn>{row.name}</TableRowColumn>
        <TableRowColumn>{row.createdBy?.username}</TableRowColumn>
        <TableRowColumn>{new Date(row.created_at).toLocaleString('nl-NL')}</TableRowColumn>
          <TableRowColumn>{new Date(row.updated_at).toLocaleString('nl-NL')}</TableRowColumn>
        <TableRowColumn>{row.updatedBy?.username}</TableRowColumn>
        <TableRowColumn>
            <div>
                <a href="#" onClick={() => props.onEditClick(row)}>
                    <i className="small material-icons left themeprimarycolor">edit</i>
                </a>
            </div>
        </TableRowColumn>
      </TableRow>
    );
  }

  const generateHeader = () => {
    return (
      props.headerNames.map(headerName =>
        <TableHeaderColumn key={headerName} className="clickable" headername={headerName} >
            {headerName}
        </TableHeaderColumn>
      )
    );
  }

  return (
      <RatioOverflowTableWrapper>
          <div className="valign-wrapper">
              <Table selectable={false}>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false} >
                      <TableRow onCellClick = {props.onHeaderClick}>
                          {
                              generateHeader()
                          }
                      </TableRow>
                  </TableHeader>
                  <TableBody displayRowCheckbox={false}>
                      {
                          props.rows.emailTemplates.map(row =>
                              generateRow(row)
                          )
                      }
                  </TableBody>
              </Table>
          </div>
      </RatioOverflowTableWrapper>
  );
};

EmailTemplatesGrid.propTypes = {
  onHeaderClick: PropTypes.func.isRequired,
  getPropertyNameFromDisplayName: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  headerNames: PropTypes.array.isRequired
}

export default EmailTemplatesGrid;