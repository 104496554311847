import React, {Component} from 'react';
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import emailTemplateActions from "../../../actions/emailTemplateActions";
import '../index.css';
import EmailTemplatesGrid from "./EmailTemplatesGrid";
import {Input} from "react-materialize";
import DialogBody from "../../common/KpnDialog/DialogBody";
import DialogHeader from "../../common/KpnDialog/DialogHeader";
import KpnDialog from "../../common/KpnDialog/KpnDialog";
import DialogFooter from "../../common/KpnDialog/DialogFooter";
import DialogButton from "../../common/KpnDialog/DialogButton";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";

const dummyVariables = [
  { name: 'Username' },
  { emailFrom: 'sender@email.com' },
  { extraText: 'Dummy text' },
  { quotationID: '1234' },
  { offerteUrl: 'offerteUrl' },
  { formalityUppercase: 'Je' },
  { formalityLowercase: 'je' },
  { organizationName: 'OrganizationName' },
  { ratioUser: 'Quote Author' },
  { ratioUserEmail: 'quoteAuthor@email.com' },
  { ratioUserPhoneNumber: '0612345678' },
  { salutation: 'Dhr. Username' },
  { authorName: 'Quote Author Name' },
  { offerteName: 'Quotation Name' },
  { contactFirstName: 'Contact Person Name' },
  { createdByUser: 'Sender Name' },
  { documentName: 'quotation_name_getekend.pdf' },
]

class EmailTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editDialogOpened: false,
      emailPreviewOpened: false,
      template: {
        id: '',
        template: '',
        templateContent: '',
        name: ''
      },
      dummyEmailTemplate: ''
    };
  }

  componentDidMount() {
    this.props.actions.getEmailTemplates();
  };


  getHeaderPropertyForName = (displayName) => {
    const index = this.tableHeaderNames.indexOf(displayName);
    return this.tableObjectProperties[index];
  };

  onEdit = (row) => {
    this.setState({editDialogOpened : true});
    this.setState({
      template: {
        id: row.id,
        name: row.name,
        template: row.template,
        templateContent: row.templateContent
      }
    })
  }

  onChange = (event) => {
    this.setState({
      template: {
        ...this.state.template,
        templateContent: event.target.value
      }
    })
  }

  closeEditDialog = () => {
    this.setState({editDialogOpened : false});
    this.setState({
      template: {
        id: '',
        name: '',
        template: '',
        templateContent: ''
      }
    });
  }

  updateEmailTemplate = () => {
    this.props.actions.updateEmailTemplate(this.state.template);
    this.setState({editDialogOpened : false});
    this.setState({emailPreviewOpened : false});
    this.setState({dummyEmailTemplate : ''});
    this.setState({
      template: {
        id: '',
        name: '',
        template: '',
        templateContent: ''
      }
    });
  }

  onOpenPreview = () => {
    let htmlString = this.state.template.templateContent;

    dummyVariables.map((value, index) => {
      const key = Object.keys(value)[0];
      const newValue = Object.values(value)[0];
      htmlString = htmlString.replace('{{ '+key+' }}', newValue);
    })

    this.setState({dummyEmailTemplate: htmlString})
    this.setState({editDialogOpened : false});
    this.setState({emailPreviewOpened : true});
  }

  onClosePreview = () => {
    this.setState({editDialogOpened : true});
    this.setState({emailPreviewOpened : false});
  }

  tableHeaderNames = ['Template name', 'CreatedBy', 'Created At', 'Updated At', 'Updated By', 'Acties'];
  tableObjectProperties = ['name', 'createdBy', 'createdAt', 'updatedAt', 'updatedBy', ''];

  render() {
    const {emailTemplates} = this.props;

    return (
      <div>
        <div id="Forms" className="ratiotable paddingbottomnone">
          <h1 className="admin-subheader-text">
            <span className="ratio-section-title">Email templates</span>
          </h1>
        </div>

        <EmailTemplatesGrid
          rows={emailTemplates}
          onHeaderClick={this.onSortChange}
          getPropertyNameFromDisplayName={this.getHeaderPropertyForName}
          headerNames={this.tableHeaderNames}
          onEditClick={this.onEdit}
        />

        <KpnDialog
            dialogState={this.state.editDialogOpened}
            modal={false}
            dialogHeader={(
                <DialogHeader
                    headerIcon="edit"
                    headerText={this.state.template.name}
                    closeHandler={this.closeEditDialog}
                />
            )}
            dialogBody={
              <DialogBody>
                <div>
                  <Input
                      s={6}
                      type="text"
                      value={this.state.template.template}
                      disabled={true}
                  />
                  <textarea
                      ref="templateContent"
                      name="templateContent"
                      onChange={(event) => this.onChange(event)}
                      className="validate"
                      style={{ height: "20rem"}}
                      value={this.state.template.templateContent}
                  />
                </div>
                <div>
                  <Accordion>
                    <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                        style={{backgroundColor: 'lightgrey', height: '50px'}}
                    >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%'}}>
                        <p>Zie legende</p>
                        <i className="small material-icons right">expand_more</i>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      {dummyVariables.map((value) => (
                          <div>
                            <span style={{ fontWeight: 'bold'}}>{Object.keys(value)}</span>
                            <span> => {Object.values(value)}</span>
                          </div>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                </div>
              </DialogBody>
            }
            dialogFooter={
              <DialogFooter
                  buttonLeft={(
                      <DialogButton
                          buttonAction={this.closeEditDialog}
                          buttonText='Annuleren'
                          left={true}
                      />
                  )}
                  buttonRight={(
                      <DialogButton
                          buttonAction={this.onOpenPreview}
                          buttonText='Voorbeeld openen'
                      />
                  )}
              />
            }
        />

        <KpnDialog
            dialogState={this.state.emailPreviewOpened}
            modal={false}
            dialogHeader={(
                <DialogHeader
                    headerIcon="edit"
                    headerText={"Email Preview"}
                    closeHandler={this.onClosePreview}
                />
            )}
            dialogBody={
              <DialogBody>
                <div>
                  <div>
                    <iframe width={'100%'} height={'300'} srcDoc={this.state.dummyEmailTemplate}></iframe>
                  </div>

                </div>
              </DialogBody>
            }
            dialogFooter={
              <DialogFooter
                  buttonLeft={(
                      <DialogButton
                          buttonAction={this.onClosePreview}
                          buttonText='Annuleren'
                          left={true}
                      />
                  )}
                  buttonRight={(
                      <DialogButton
                          buttonAction={this.updateEmailTemplate}
                          buttonText='Opslaan'
                      />
                  )}
              />
            }
        />

      </div>
    )
  }
}

const mapStateToProps = ({emailTemplates, authentication}) => {
  return {
    emailTemplates,
    authentication,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(emailTemplateActions, dispatch),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EmailTemplates));
