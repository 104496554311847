import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';
import AuthenticationService from '../services/authenticationService'
import {toastr} from 'react-redux-toastr'

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

import ratioTheme from '../constants/theme';

const prefixFileUrl = (logoUrl) => {
    let urlPrefixed = logoUrl;

    if (logoUrl && logoUrl.indexOf('http') === -1) {
        const siteURL = process.env.REACT_APP_API_URL;
        const prefix = siteURL.replace('/api/', '');
        urlPrefixed = logoUrl ? `${prefix}${logoUrl}` : logoUrl;
    }

    return urlPrefixed;
};

const loginCheck = (username, password) => {
    return async (dispatch) => {
        try {
            let body = {
                _username: username,
                _password: password,
            };

            let url = apiRoutes.authenticationRelated.login;
            let requestData = await ApiService.performRequest(RequestType.POST, url, body);

            dispatch(_loginCheckSuccess());
            return requestData;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                if (error.response.data.message === 'failedLogin') {
                    toastr.error('U hebt vijf keer een onjuist wachtwoord ingevuld.', 'U kunt over ' + error.response.data.minutes + ' minuten nogmaals proberen in te loggen.');
                }
                if (error.response.data.message === 'blockedLogin') {
                    toastr.error('Toegang is per 1-10-2022 niet meer mogelijk.');
                }
            } else {
                toastr.error('Verkeerde wachtwoord of gebruikersnaam', 'Probeer opnieuw.');
            }

            dispatch(_loginFailed(error));
        }
    }
}

const _loginCheckSuccess = () => {
  return {
    type: actionTypes.loginCheckSuccess
  }
}

const login = (username, password, code, organization) => {
    return async (dispatch) => {
        try {
            let body = {
                _username: username,
                _password: password,
                code: code,
                organization: organization ?? null,
            }

            let url = apiRoutes.authenticationRelated.getToken;
            let requestData = await ApiService.performRequest(RequestType.POST, url, body);
            let { token, organizationStyling } = requestData;

            const theme = organizationStyling ?
              {
                ...ratioTheme,
                ...organizationStyling,
                logo: organizationStyling.logo ? prefixFileUrl(organizationStyling.logo) : ratioTheme.logo,
                background: prefixFileUrl(organizationStyling.background),
                primaryColor: organizationStyling.palette.primaryColor,
                secondaryColor: organizationStyling.palette.secondaryColor,
              } : null;

            AuthenticationService.setToken(token);
            AuthenticationService.setTheme(theme);
            toastr.success('Succesvol ingelogd!');

            dispatch(getUserInfo());
            dispatch(_loginSuccess());
        } catch (error) {
            toastr.error('Verkeerde wachtwoord of gebruikersnaam', 'Probeer opnieuw.');
            dispatch(_loginFailed(error));
        }
    }
}

const _loginSuccess = () => {
    return {
        type: actionTypes.loginSuccess
    }
}

const setUserInfoIsLoading = (isLoading) => ({
    type: actionTypes.setUserInfoIsLoading,
    payload: isLoading,
});

export const getUserInfo = () => {
    return async(dispatch) => {
        dispatch(setUserInfoIsLoading(true));
        try {
            const url = apiRoutes.authenticationRelated.info;
            const requestData = await ApiService.performRequest(RequestType.GET, url);
            const { name, username, company, roles, type, allowedDiscount, isSalesValue, isFlash, isAxoftUser, isDoceri, isPHC, nameInitials,
                ordering, organizationStyling, providers, quotationValidity, oneTimePartnerService, versioning,
                readonlyOfferExport, userInsight, crmConnection, kpnITServiceOption, salesValueAcronisOption, isPIT,
                allowDigitalSigning, useNina, allowLead, allowWebLead, hasCustomPortfolio, allowQAQuotation, showQAPrices,
                showQALicences, isAdminFromDoceri, allowedUsersToNina, organizationId, hasConnectWiseSync, downloadAccess, hasQuoteBtn,
                isVenecoOrganization, allowOrder, switchableOrganizationList, allowSwitch, userId, allowDealsheet, isYielderPartner
            } = requestData;
            const theme = organizationStyling ?
              {
                ...ratioTheme,
                ...organizationStyling,
                logo: organizationStyling.logo ? prefixFileUrl(organizationStyling.logo) : ratioTheme.logo,
                background: prefixFileUrl(organizationStyling.background),
                primaryColor: organizationStyling.palette.primaryColor,
                secondaryColor: organizationStyling.palette.secondaryColor,
              } : null;
            AuthenticationService.setTheme(theme);
            dispatch(setUserDetails(name, username, company, roles, type, allowedDiscount, isSalesValue, isFlash, isAxoftUser, isDoceri,
              isPHC, nameInitials, ordering, theme, providers, quotationValidity, oneTimePartnerService, versioning,
              readonlyOfferExport, userInsight, crmConnection, kpnITServiceOption, salesValueAcronisOption, isPIT,
              allowDigitalSigning, useNina, allowLead, allowWebLead, hasCustomPortfolio, allowQAQuotation, showQAPrices,
              showQALicences, isAdminFromDoceri, allowedUsersToNina, organizationId, hasConnectWiseSync, downloadAccess, hasQuoteBtn,
              isVenecoOrganization, allowOrder, switchableOrganizationList, allowSwitch, userId, allowDealsheet, isYielderPartner)
            );
            dispatch(setUserInfoIsLoading(false));
        } catch (error) {
            dispatch(setUserInfoIsLoading(false));
        }
    }
}

const setUserDetails = (name, username, company, roles, type, allowedDiscount, isSalesValue, isFlash, isAxoftUser, isDoceri, isPHC,
                        nameInitials, ordering, theme, providers, quotationValidity, oneTimePartnerService, versioning,
                        readonlyOfferExport, userInsight, crmConnection, kpnITServiceOption, salesValueAcronisOption, isPIT,
                        allowDigitalSigning, useNina, allowLead, allowWebLead, hasCustomPortfolio, allowQAQuotation, showQAPrices, showQALicences,
                        isAdminFromDoceri, allowedUsersToNina, organizationId, hasConnectWiseSync, downloadAccess, hasQuoteBtn,
                        isVenecoOrganization, allowOrder, switchableOrganizationList, allowSwitch, userId, allowDealsheet, isYielderPartner) => {
    return {
        type: actionTypes.setUserDetails,
        payload: {
            name,
            username,
            company,
            roles,
            type,
            allowedDiscount,
            isSalesValue,
            isFlash,
            isAxoftUser,
            isDoceri,
            isPHC,
            nameInitials,
            ordering,
            theme,
            providers,
            quotationValidity,
            oneTimePartnerService,
            versioning,
            readonlyOfferExport,
            userInsight,
            crmConnection,
            kpnITServiceOption,
            salesValueAcronisOption,
            isPIT,
            allowDigitalSigning,
            useNina,
            allowLead,
            allowWebLead,
            hasCustomPortfolio,
            allowQAQuotation,
            showQAPrices,
            showQALicences,
            isAdminFromDoceri,
            allowedUsersToNina,
            organizationId,
            hasConnectWiseSync,
            downloadAccess,
            hasQuoteBtn,
            isVenecoOrganization,
            allowOrder,
            switchableOrganizationList,
            allowSwitch,
            userId,
            allowDealsheet,
            isYielderPartner
        }
    };
};

const _loginFailed = (error) => {
    return {
        type: actionTypes.loginFailed,
        payload: error
    }
}

const logout = () => {

    if (localStorage.getItem('token')) {
        try {
            let url = apiRoutes.authenticationRelated.userResetOrganization;
            ApiService.performRequest(RequestType.PATCH, url);
        } catch(error) {
            toastr.error('User not reset');
            return {success: false};
        }
    }

    return dispatch => {
        AuthenticationService.logout();
        dispatch(_logoutSuccess())
    }
}

const sessionHasExpired = () => {
  return dispatch => {
    dispatch(_sessionExpired());
  }
}

const _sessionExpired = () => {
  return {
    type: actionTypes.setExpiredSession
  }
}

const _logoutSuccess = () => {
    return {
        type: actionTypes.logoutSuccess
    }
}

const requestPasswordReset = async (username) => {

    try {
        let url = apiRoutes.passwordResetRelated.requestPasswordReset;

        let response = await ApiService.performRequest(RequestType.POST, url, {username});

        return response;
    } catch(error) {
        toastr.error('User not found');
        return {success: false};
    }

};

const updateTheme = (data) => {
    return async (dispatch) => {
        try {
            const url = apiRoutes.authenticationRelated.updateTheme;
            const themeData = { ...data };
            const themeFormData = new FormData();

            if (themeData.logo && typeof themeData.logo === 'object') {
                themeFormData.append('logoFile', themeData.logo);
            }

            if (themeData.background && typeof themeData.background === 'object') {
                themeFormData.append('backgroundFile', themeData.background);
            }

            delete themeData.logo;
            delete themeData.background;

            if (themeData.removeLogoFile) {
                themeFormData.append('removeLogoFile', '');
            }

            if (themeData.removeBackgroundFile) {
                themeFormData.append('removeBackgroundFile', '');
            }

            themeFormData.append('backgroundOpacity', themeData.backgroundOpacity);

            Object.keys(themeData.palette).forEach((key) => {
                themeFormData.append(key, JSON.stringify(themeData.palette[key]));
            });

            const response = await ApiService.performRequest(RequestType.POST, url, themeFormData, 'multipart/form-data');

            const theme = {
                ...ratioTheme,
                ...response,
                logo: response.logo ? prefixFileUrl(response.logo) : ratioTheme.logo,
                background: prefixFileUrl(response.background),
                primaryColor: response.palette.primaryColor,
                secondaryColor: response.palette.secondaryColor,
            };

            AuthenticationService.setTheme(theme);
            dispatch(updateThemeSuccess(theme));

            toastr.success('Thema met succes bewerkt');
        } catch (exception) {
            toastr.error('Het was niet mogelijk om het thema te bewerken.');
        }
    }
};

const updateThemeSuccess = (response) => {
    return {
        type: actionTypes.updateThemeSuccess,
        response,
    };
};

const getTheme = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.authenticationRelated.getTheme;

            const response = await ApiService.performRequest(RequestType.GET, url);

            const theme = {
                ...ratioTheme,
                ...response,
                logo: response.logo ? prefixFileUrl(response.logo) : ratioTheme.logo,
                background: prefixFileUrl(response.background),
            };
            AuthenticationService.setTheme(theme);
            dispatch(updateThemeSuccess(theme));
        } catch(error) {
            toastr.error('De themagegevens kunnen niet worden opgehaald.');
        }
    }
};

export const getOrganizationInfo = () => {
    return async(dispatch) => {
        try
        {
            const url = apiRoutes.authenticationRelated.organizationInfo.replace(/{domain}/, window.location.hostname);
            const requestData = await ApiService.performRequest(RequestType.GET, url);
            const { organizationStyling } = requestData;
            const theme = organizationStyling ?
                {
                    ...ratioTheme,
                    ...organizationStyling,
                    logo: organizationStyling.logo ? prefixFileUrl(organizationStyling.logo) : ratioTheme.logo,
                    background: prefixFileUrl(organizationStyling.background),
                    primaryColor: organizationStyling.palette.primaryColor,
                    secondaryColor: organizationStyling.palette.secondaryColor,
                } : null;
            AuthenticationService.setTheme(theme);
            dispatch(updateThemeSuccess(theme));
        }
        catch (error)
        {
            toastr.error('Could not get organization info.');
        }
    }
};

export const getSwitchableOrganizations = () => {
    return async(dispatch) => {
        try {
            const url = apiRoutes.authenticationRelated.getSwitchableOrganizations;
            const result = await ApiService.performRequest(RequestType.GET, url);
            dispatch(getSwitchableOrganizationsSuccess(result['canBeSwitchedOrganizationList']));
        }
        catch (error) {
            toastr.error('Could not get switchable organizations.');
        }
    }
}

const getSwitchableOrganizationsSuccess = (response) => {
    return {
        type: actionTypes.getSwitchableOrganizationsSuccess,
        response,
    };
};

export const switchOrganization = (organizationId) => {
    return async(dispatch) => {
        try {
            let body = {
                organizationId: organizationId,
            }
            const url = apiRoutes.authenticationRelated.switchOrganization;
            const result = await ApiService.performRequest(RequestType.PATCH, url, body);
            dispatch(switchOrganizationSuccess(result['success']));
        }
        catch (error) {
            toastr.error('Could not switch to the new organization.');
        }
    }
}

const switchOrganizationSuccess = (response) => {
    return {
        type: actionTypes.switchOrganizationSuccess,
        response,
    };
};

export default {
    login,
    logout,
    getUserInfo,
    setUserInfoIsLoading,
    loginCheck,
    requestPasswordReset,
    sessionHasExpired,
    updateTheme,
    getTheme,
    getOrganizationInfo,
    getSwitchableOrganizations,
    switchOrganization
}
