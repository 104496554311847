import {
  actionTypes
} from '../constants/actionTypes';

const initialState = {
  emailTemplates: [],
};

export default (state = initialState, action) => {

  switch(action.type) {
    case actionTypes.getEmailTemplates:
      return {
        ...state,
        emailTemplates: action.response.templates,
      };
    case actionTypes.updateEmailTemplate:
      return {
        ...state,
        emailTemplates: action.templates
      };
    default:
      return state;
  }
}
