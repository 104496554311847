import { actionTypes } from '../constants/actionTypes';

const adminMenuItems = [
  {
    id: 'organizations',
    displayName: 'Organisaties',
    iconType: 'business',
    route: '/admin/organizations',
  },
  {
    id: 'users',
    displayName: 'Gebruikers',
    iconType: 'people',
    route: '/admin/users',
  },
  {
    id: 'settings',
    displayName: 'Instellingen',
    iconType: 'business',
    route: '/admin/settings',
  },
  {
    id: 'themes',
    displayName: 'Themas',
    iconType: 'palette',
    route: '/admin/themes',
  },
  // {
  //   id: 'hardware',
  //   displayName: 'Hardware opties',
  //   iconType: 'router',
  //   route: '/admin/hardware-options',
  // },
  // {
  //   id: 'services',
  //   displayName: 'Partner en IT Diensten',
  //   iconType: 'business',
  //   route: '/admin/partners-services',
  // },
  {
    id: 'compensations',
    displayName: 'Vergoeding',
    iconType: 'euro',
    route: '/admin/compensations',
  },
  {
    id: 'word-documents',
    displayName: 'Word documenten',
    iconType: 'file_copy',
    route: '/admin/word-documents'
  },
  {
    id: 'products-overview',
    displayName: 'Eigen producten en diensten',
    iconType: 'router',
    route: '/admin/products-overview'
  },
  {
    id: 'products-adjusting',
    displayName: 'PFS-producten',
    iconType: 'router',
    route: '/admin/products-adjusting'
  },
  {
    id: 'data-management',
    displayName: 'Data management',
    iconType: 'router',
    route: '/admin/data-management'
  },
  {
    id: 'tags-management',
    route: '/admin/tags-management'
  },
  {
    id: 'tag-categories-management',
    route: '/admin/tag-categories-management'
  },
  {
    id: 'refused-postcode-management',
    route: '/admin/refused-postcode-management'
  },
  {
    id: 'postcode-categories-management',
    route: '/admin/postcode-categories-management'
  },
  {
    id: 'downloads',
    displayName: 'Downloads',
    iconType: 'file_download',
    route: '/admin/downloads'
  },
  {
    id: 'digital-signing',
    displayName: 'Custom velden',
    iconType: 'create',
    route: '/admin/digital-signing'
  },
  {
    id: 'terms-and-conditions',
    displayName: 'Voorwaarden',
    iconType: 'create',
    route: '/admin/terms-and-conditions'
  },
  {
    id: 'attachments',
    displayName: 'Bijlagen',
    iconType: 'create',
    route: '/admin/attachments'
  },
  {
    id: 'organization-providers-logos',
    displayName: 'Email Logos',
    iconType: 'create',
    route: '/admin/organization-providers-logos'
  },
  {
    id: 'orders-overview',
    displayName: 'Orders Overview',
    iconType: 'assignment',
    route: '/admin/orders-overview'
  },
  {
    id: 'email-templates',
    displayName: 'Email Templates',
    iconType: 'email',
    route: '/admin/email-templates'
  }
];

const initialState = {
  currentSectionId: null,
  adminMenuItems: adminMenuItems,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.setCurrentAdminSection:
            return {
              ...state,
              currentSectionId: action.payload
            };
        default:
            return state;
    }
}
