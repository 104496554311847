import React, {Component} from 'react';
import _ from "lodash";
import {connect} from 'react-redux';
import debounce from 'lodash/debounce';
import {bindActionCreators} from 'redux';
import {toastr} from "react-redux-toastr";
import {withRouter} from "react-router-dom";
import {Table, TableBody} from "material-ui/Table";
import {Col, Input, Preloader, Row} from 'react-materialize';
import {formatDate, poll, sleep, uniquePoll} from "../../../utils/utils";

import FileDownload from 'js-file-download';
import {
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from 'material-ui';

import discountActions from '../../../actions/workflow/discountActions';
import quotationActions from '../../../actions/quotationActions';
import wordDocumentActions from "../../../actions/wordDocumentActions";
import discountProductActions from "../../../actions/discountProductActions";
import quotationDocumentActions from "../../../actions/quotationDocumentActions";
import signedDocumentActions from '../../../actions/signedDocumentActions'
import termsAndConditionsActions from '../../../actions/termsAndConditionsActions'
import stepsActions from "../../../actions/stepsActions";

import GroupProducts from './GroupProducts';
import ModuleProducts from '../overview/ModuleProducts';
import DiscountProduct from "../overview/DiscountProduct";
import Organization from '../../../constants/organization';
import ProviderHelper from "../../../helper/providerHelper";
import LoaderComponent from '../../../containers/common/LoaderComponent';
import RefreshQuotation from "../../../containers/common/refreshQuotation";
import SyncConnectWiseQuotation from "../../../containers/common/syncConnectWiseQuotation";

import TotalTables from './totalTables';
import DatePicker from "../../../components/datePicker";
import DoceriDropDownMenu from "../../../components/DoceriDropDownMenu/DoceriDropDownMenu";

import {apiRoutes} from "../../../constants/apiRoutes";
import providerType, {odidoProviders} from "../../../constants/providerType";
import quotationType from "../../../constants/quotationType";

import DialogHeader from "../../../containers/common/KpnDialog/DialogHeader";
import DialogBody from "../../../containers/common/KpnDialog/DialogBody";
import DialogFooter from "../../../containers/common/KpnDialog/DialogFooter";
import DialogButton from "../../../containers/common/KpnDialog/DialogButton";
import KpnDialog from "../../../containers/common/KpnDialog/KpnDialog";
import GeneralDialog from "../../../containers/common/GeneralDialog";
import FileUpload from "../../../components/fileUpload";
import orderingActions from "../../../actions/workflow/orderingActions";
import {DelayInput} from "react-delay-input";

class DiscountOverview extends Component {
  state = {
    loading: false,
    uploadFormOpen: false,
    uploadDocumentType: '',
    uploadDocument: {
      name: '',
      file: '',
      documentType: '',
    },
    productGroups: [],
    flatProducts: [],
    modules: [],
    kwtCode: {
      value: '',
      disabled: true
    },
    extraKwtCode: {
      value: '',
      disabled: true
    },
    combinationDiscount: '',
    selectedDocumentId: null,
    loader: false,
    dynamicsData: {
      sendDynamicsDataDialog: false,
      productName: ''
    },
    emailDialog: false,
    emailDialogConfirmation: false,
    emailData: {
      name: '',
      email: '',
      mobileNumber: '',
      extraText: '',
      sameAuthorizedSignatory: true,
      signName: ''
    },
    emailLock: false,
    signedDocumentDialog: false,
    signedDocument: this.props.signedDocument.document,
    missingSVProducts: [],
    missingProductsDialog: false,
    quotationSentAt: null,
    openDateDialog: false,
    isSignNow: false,
    failedApprovalQuestions: [],
    missingConnectWiseProducts: [],
    missingCWProductsDialog: false,
    kwtRequiredState: this.props.quotation.currentQuotation.kwtRequired,
    kwtValidState: this.props.quotation.currentQuotation.kwtValid,
    mandateDialog: false,
    mandateData: {
      type: '',
      notes: ''
    },
    discountNotes: '',
    discountStatus: '',
    extraKwtInputs: [{code: ''}],
    placedYielderOrder: this.props.quotation.currentQuotation.yielderOrderPlaced,
    placedYielderOrderActive: this.props.quotation.currentQuotation.digitalSigningTermsAndConditions ?? false,
    yielderOrderModalOpen: false,
    tcFiles: [],
  };

  componentWillMount() {
    if (this.props.editable && !this.props.quotation.isLead) {
      this.props.discountProductActions.getDiscountProduct(this.props.quotation.currentQuotation.id);
    }
  };

  componentWillReceiveProps(newProps) {
    const {discount} = newProps;
    const {discountTables} = discount;
    const productGroups = discountTables.clusters || {};

    let flatProducts = [];

    if (discountTables.data) {
      discountTables.data.forEach((item) => {
        const {products} = item;

        products.forEach((product) => {
          flatProducts = [...flatProducts, product];
        });
      });
    }

    let emailData = this.state.emailData;
    if (discountTables.emailData !== undefined) {
      emailData['signName'] = discountTables.emailData.signName;
      emailData['sameAuthorizedSignatory'] = discountTables.emailData.sameAuthorizedSignatory;
    } else {
      emailData['signName'] = '';
      emailData['sameAuthorizedSignatory'] = true;
    }

    this.setState({
      productGroups: Object.keys(productGroups).map(
        (key) => productGroups[key],
      ),
      modules: discountTables.data,
      flatProducts: flatProducts,
      kwtCode: discountTables.kwtCode,
      extraKwtInputs: discountTables?.extraKwtCodes?.length ? discountTables.extraKwtCodes : this.state.extraKwtInputs,
      failedApprovalQuestions: discountTables.failedApprovalQuestions,
      combinationDiscount: discountTables.combinationDiscount,
      discountStatus: discountTables.discountStatus,
      discountNotes: discountTables.discountNotes,
      emailData: emailData
    });
  };

  componentDidMount() {
    this.setState({loading: true});

    const {hasConnectWiseSync, isSalesValue, isVenecoOrganization} = this.props.authentication;
    const {isLead, currentQuotation} = this.props.quotation;

    const quotationId = currentQuotation.id;
    const {type, provider} = currentQuotation;
    const isQAQuotation = type === quotationType.TYPE_QA;
    let failedApprovalMessage = 'De configuratie van Eenvoud uit de Cloud lijkt niet te kloppen. Check nogmaals alle waarden met rode meldingen';

    if (isSalesValue && !this.props.editable) {
      const url = apiRoutes.discountRelated.syncSalesForceOpportunity.replace(/{quotation}/, quotationId);

      this.props.discountActions.syncSalesForceOpportunity(quotationId)
        .then(() => uniquePoll(url))
        .then((response) => {
          if (response) {
            const existMissingProducts = response.missingProducts ? response.missingProducts.length > 0 : false;

            if (response.success && existMissingProducts) {
              this.setState({
                missingSVProducts: response.missingProducts,
                missingProductsDialog: true
              })
            }
          }
        })
        .catch((error) => {
          const message = _.has(error, 'response.data') ? error.response.data : 'Het synchroniseren naar SalesForce is nog bezig. Ververs uw pagina aub';

          toastr.error(message, error);
        });

      this.props.discountActions.getDiscountState(quotationId, this.props.editable, isLead)
        .then(() => this.props.wordDocumentActions.getWordDocuments(currentQuotation.provider, currentQuotation.isWebLead))
        .then(() => this.props.quotationDocumentActions.getQuotationDocuments(currentQuotation.id))
        .then(() => {
          let {discountTables} = this.props.discount;
          let {quotationSentAt, failedApprovalQuestions} = discountTables;

          if (failedApprovalQuestions.length) {
            this.props.quotationActions.addSoftAlert(failedApprovalMessage);
          } else {
            this.props.quotationActions.removeSpecificSoftAlert(failedApprovalMessage);
          }

          if (isQAQuotation) {
            this.props.quotationActions.checkQuestionsApproval(quotationId, failedApprovalQuestions);
          }

          this.setState({loading: false, quotationSentAt: quotationSentAt ? new Date(quotationSentAt) : null})
        })

    } else {
      if (hasConnectWiseSync && !this.props.editable) {
        this.props.discountActions.getConnectWiseMissingProducts(quotationId).then((response) => {
          if (response && response.success && response.missingProducts) {
            this.setState({
              missingConnectWiseProducts: response.missingProducts,
              missingCWProductsDialog: response.missingProducts.length > 0
            })
          }
        })
      }

      this.props.discountActions.getDiscountState(quotationId, this.props.editable, isLead).then(() => {
        this.props.wordDocumentActions.getWordDocuments(currentQuotation.provider, currentQuotation.isWebLead).then(() => {
          let {discountTables} = this.props.discount;
          let {quotationSentAt, failedApprovalQuestions, syncError} = discountTables;

          if (failedApprovalQuestions?.length) {
            this.props.quotationActions.addSoftAlert(failedApprovalMessage);
          } else {
            this.props.quotationActions.removeSpecificSoftAlert(failedApprovalMessage);
          }

          if (syncError?.length) {
            toastr.error(syncError);
          }

          if (isQAQuotation) {
            this.props.quotationActions.checkQuestionsApproval(quotationId, failedApprovalQuestions);
          }

          this.setState({loading: false, quotationSentAt: quotationSentAt ? new Date(quotationSentAt) : null});
        });
        this.props.quotationDocumentActions.getQuotationDocuments(currentQuotation.id);
      })
    }

    this.props.signedDocumentActions.getSignedDocument(quotationId);

    this.updateKwtCode = debounce(this.updateKwtCode, 1000);
    this.updateExtraKwtCode = debounce(this.updateExtraKwtCode, 1000);
    this.continuePoll();

    const hasDiscountSelection = ProviderHelper.hasModuleEnabled(currentQuotation, 'discountSelections');

    if (isVenecoOrganization && hasDiscountSelection && [providerType.VODAFONE, providerType.KPN].includes(provider)) {
      const {kwtValidState} = this.state;

      this.handleKwtWarning(!kwtValidState);
      this.props.stepsActions.setAvailableToGoForward(kwtValidState);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let prevDiscountTables = prevProps.discount.discountTables;
    let currentDiscountTables = this.props.discount.discountTables;
    if (prevDiscountTables !== currentDiscountTables) {
      this.setState({quotationSentAt: currentDiscountTables?.quotationSentAt ? new Date(currentDiscountTables.quotationSentAt) : null});
    }

    if (prevProps.signedDocument.document !== this.props.signedDocument.document) {
      this.setState({signedDocument: this.props.signedDocument.document})
    }

    const {isVenecoOrganization} = this.props.authentication;
    const {currentQuotation} = this.props.quotation;
    const hasDiscountSelection = ProviderHelper.hasModuleEnabled(currentQuotation, 'discountSelections')
    if (isVenecoOrganization && hasDiscountSelection) {
      let kwtValidPrevState = prevState.kwtValidState;
      let currentKWTValidState = this.state.kwtValidState;

      if (kwtValidPrevState !== currentKWTValidState) {
        this.handleKwtWarning(!currentKWTValidState);
        this.props.stepsActions.setAvailableToGoForward(currentKWTValidState);
      }
    }
  }

  continuePoll = () => {
    let {isLead, currentQuotation} = this.props.quotation;
    let {id, name} = currentQuotation;

    if (this.props.quotation.currentQuotation.pendingQuoteDocument) {
      let quoteUrl = apiRoutes.wordDocumentRelated.generateQuote;
      const selectedDocumentId = localStorage.getItem('selectedQuoteDocumentId');
      quoteUrl = quoteUrl.replace('{documentId}', selectedDocumentId).replace('{quotationId}', id);

      poll(quoteUrl, {responseType: 'arraybuffer'}) // Use array buffer instead of blob, see link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
        .then((response) => {
          FileDownload(response, `${name}.'pdf'`);
        })
        .catch((e) => toastr.error('Kan word document niet downloaden', e.message))

    } else {
      if (!this.props.quotation.currentQuotation.pendingDocument) {
        return;
      }

      const selectedDocumentId = localStorage.getItem('selectedDocumentId');

      let url = isLead ? apiRoutes.wordDocumentRelated.generateLeadDocument : apiRoutes.wordDocumentRelated.generate;
      url = url.replace('{documentId}', selectedDocumentId).replace(isLead ? '{leadId}' : '{quotationId}', id);

      poll(url, {responseType: 'arraybuffer'}) // Use array buffer instead of blob, see link https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/responseType
        .then((response) => {
          const {readonlyOfferExport} = this.props.authentication;
          FileDownload(response, `${name}.${readonlyOfferExport ? 'pdf' : 'docx'}`);
        })
        .catch((e) => toastr.error('Kan word document niet downloaden', e.message))
    }
  };

  handleKwtWarning = (enabled) => {
    let alertMessage = 'KWT-gegevens ontbreken!';

    enabled ?
        this.props.quotationActions.addSoftAlert(alertMessage) :
        this.props.quotationActions.removeSpecificSoftAlert(alertMessage);
  }

  openUploadDocument = (documentType) => {
    this.setState({
      uploadFormOpen: true,
      uploadDocumentType: documentType
    });

    this.setState(prevState => ({
      uploadDocument: {
        ...prevState.uploadDocument,
        documentType: documentType
      }
    }));
  };

  closeUploadDocumentForm = () => {
    this.setState({
      uploadFormOpen: false,
      uploadDocumentType: '',
      uploadDocument: {
        name: '',
        file: '',
        documentType: '',
      }
    });
  };

  onSubmitUploadDocument = (e) => {
    e.preventDefault();

    const {uploadDocument} = this.state;
    const quotationId = this.props.quotation.currentQuotation.id;

    this.props.quotationDocumentActions.addQuotationDocument(quotationId, uploadDocument).then((response) => {
      this.closeUploadDocumentForm();
      if (response) {
        if (response.success) {
          toastr.success('Document succesvol geüpload!');
        } else {
          toastr.error('Toevoegen mislukt!');
        }
      }
    })
  };

  onChangeUploadDocument = ({target}) => {
    let {name, value} = target;

    this.setState(prevState => ({
      uploadDocument: {
        ...prevState.uploadDocument,
        [name]: value
      }
    }));
  };

  onDropUploadDocument = (files) => {
    const file = files[0];

    this.setState(prevState => ({
      uploadDocument: {
        ...prevState.uploadDocument,
        file: file,
      }
    }));
  };

  updateKwtCode = (quotationId, value) => {
    this.props.discountActions.updateKwtCode(quotationId, value).then(() =>
      this.props.discountActions.getDiscountState(quotationId, this.props.editable),
    );
  };

  updateExtraKwtCode = (quotationId, values) => {
    this.props.discountActions.updateExtraKwtCode(quotationId, values).then(() =>
      this.props.discountActions.getDiscountState(quotationId, this.props.editable),
    );
  };

  onChangeKwtCode = (event) => {
    const {value} = event.target;
    const quotationId = this.props.quotation.currentQuotation.id;
    const alphanum = new RegExp('^[A-Za-z0-9,-]*$');
    const {isVenecoOrganization} = this.props.authentication

    if (!alphanum.test(value) || this.state.kwtCode.disabled) {
      return;
    }

    if (isVenecoOrganization) {
      if (value === '' && this.state.kwtValidState === true) {
        this.setState({kwtValidState: false});
      }

      if (value !== '' && this.state.kwtValidState === false) {
        this.setState({kwtValidState: true});
      }
    }

    this.setState({kwtCode: {value, disabled: this.state.kwtCode.disabled}});
    this.updateKwtCode(quotationId, value);
  };

  onChangeExtraKwtCode = (event, index) => {
    const {value} = event.target;
    const quotationId = this.props.quotation.currentQuotation.id;
    const alphanum = new RegExp('^[A-Za-z0-9]*$');

    if (!alphanum.test(value)) {
      return;
    }

    let values = this.state.extraKwtInputs;
    values[index]['code'] = value;
    this.setState({...values});

    this.updateExtraKwtCode(quotationId, this.state.extraKwtInputs);
  };

  handleAddExtraInput = () => {
    this.setState({
      extraKwtInputs: [
        ...this.state.extraKwtInputs,
        {code: ''}
      ]
    })
  }

  onChangeCombinationDiscount = (event) => {
    const {value} = event.target;
    const quotationId = this.props.quotation.currentQuotation.id;

    this.setState({combinationDiscount: value});
    this.updateCombinationDiscount(quotationId, value);
  };

  onChangeDiscount = (name, value) => {
    this.props.discountProductActions.editDiscountProduct(this.props.quotation.currentQuotation.id, {[name]: value}).then(() => {
      this.props.discountActions.getDiscountState(this.props.quotation.currentQuotation.id, this.props.editable);
    });
  };

  updateCombinationDiscount = (quotationId, value) => {
    this.props.discountActions.updateCombinationDiscount(quotationId, value).then(() =>
      this.props.discountActions.getDiscountState(quotationId, this.props.editable),
    );
  };

  generateTotalHeaders = () => {
    return (
      <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
        <TableRow>
          <TableHeaderColumn>Naam</TableHeaderColumn>
          <TableHeaderColumn>Bruto prijs</TableHeaderColumn>
          <TableHeaderColumn>Korting</TableHeaderColumn>
          <TableHeaderColumn>Netto prijs</TableHeaderColumn>
        </TableRow>
      </TableHeader>
    );
  };

  getProductsByDiscriminator = (discriminator) => {
    return this.props.discount.discountTables.totals.find(
      (x) => x.name === discriminator,
    ).products;
  };

  getProductsTotal = () => {
    let totalProductsMonthlyDiscount;
    let totalProductsOneOffDiscount;

    for (let product of this.getProductsByDiscriminator('Monthly costs')) {
      if (product.name === 'Totaal') {
        totalProductsMonthlyDiscount = product.price;
        break;
      }
    }

    for (let product of this.getProductsByDiscriminator('One time cost')) {
      if (product.name === 'Totaal') {
        totalProductsOneOffDiscount = product.price;
        break;
      }
    }

    return {
      'monthlyCosts': totalProductsMonthlyDiscount,
      'oneOff': totalProductsOneOffDiscount
    };
  }

  generateTotalRows = (discriminator) => {
    const products = this.getProductsByDiscriminator(discriminator);

    return products.map((product) => (
      <TableRow
        key={
          product.isSwapstock ? `${product.id}_SWAPSTOCK` : product.id
        }
        className="totals"
      >
        <TableRowColumn
          style={{whiteSpace: 'normal', wordWrap: 'break-word'}}
        >
          {product.name} {product.fetchedFromGrexx &&
        <span style={{color: 'red', fontStyle: 'italic'}}>(LET OP: prijs onbekend)</span>}
        </TableRowColumn>
        <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.price)}
        </TableRowColumn>
        <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.discount)}
        </TableRowColumn>
        <TableRowColumn style={product.fetchedFromGrexx ? {textAlign: 'right', color: 'red'} : {textAlign: 'right'}}>
          {new Intl.NumberFormat('nl-NL', {
            style: 'currency',
            currency: 'EUR',
          }).format(product.price - product.discount)}
        </TableRowColumn>
      </TableRow>
    ));
  };

  downloadWordDocument = (selectedDocumentId) => {
    const {isLead, currentQuotation} = this.props.quotation;
    const quotationId = currentQuotation.id;
    const creationDate = currentQuotation.createdAt.toLocaleString();
    const newDate = new Date(creationDate);
    const formattedDate = `${newDate.getFullYear()}${newDate.getMonth() + 1}${newDate.getDate()}`;
    const postalCode = currentQuotation.customerData.postalCode;
    const houseNumber = currentQuotation.customerData.houseNumber;
    const {documents, excelExtensions} = this.props.wordDocuments;
    const {readonlyOfferExport} = this.props.authentication;

    let quotationName = currentQuotation.name;
    let documentType = 'docx';

    if (this.props.authentication.company === Organization.BIB || this.props.authentication.company === Organization.CLEARMIND) {
      quotationName = formattedDate + ' Clear Mind IT Consultancy Offerte ' + postalCode + ' ' + houseNumber;
    }

    if (selectedDocumentId === null) {
      selectedDocumentId = [0].id;
    }

    let selectedDocument = documents.find((document) => document.id === selectedDocumentId);
    if (selectedDocument) {
      documentType = selectedDocument.documentType;
    }

    let isExcel = excelExtensions.includes(documentType);
    let documentName = `${quotationName}.${isExcel ? documentType : (readonlyOfferExport ? 'pdf' : 'docx')}`;

    localStorage.setItem('selectedDocumentId', selectedDocumentId);
    let url = isLead ? apiRoutes.wordDocumentRelated.generateLeadDocument : apiRoutes.wordDocumentRelated.generate;
    url = url.replace('{documentId}', selectedDocumentId).replace(isLead ? '{leadId}' : '{quotationId}', quotationId);

    this.setWordFileDownloadAlert();

    this.props.wordDocumentActions.generateWordDocument(selectedDocumentId, quotationId, isLead)
      .then(() => poll(url, {responseType: 'arraybuffer'})) // Use array buffer instead of blob, see link https://stackoverflow.com/questions/55106303/angular-fail-to-download-file-10mb-from-server-on-google-chrome
      .then((response) => {
        FileDownload(response, documentName);

        this.props.quotationActions.removeLastSoftAlert();
      }).catch((e) => {
      this.props.quotationActions.removeLastSoftAlert();
      toastr.error('Kan word document niet downloaden', e.message)
    });

    this.hideDownloadsDropdown();
  };

  downloadQuoteWordDocument = (selectedDocumentId) => {

    const {currentQuotation} = this.props.quotation;
    const quotationId = currentQuotation.id;

    let quotationName = currentQuotation.name;

    if (selectedDocumentId === null) {
      selectedDocumentId = [0].id;
    }

    let documentName = `${quotationName}.pdf`;

    localStorage.setItem('selectedQuoteDocumentId', selectedDocumentId);
    let url = apiRoutes.wordDocumentRelated.generateQuote;
    url = url.replace('{documentId}', selectedDocumentId).replace('{quotationId}', quotationId);

    this.setWordFileDownloadAlert();

    this.props.wordDocumentActions.generateQuoteWordDocument(selectedDocumentId, quotationId)
      .then(() => poll(url, {responseType: 'arraybuffer'})) // Use array buffer instead of blob, see link https://stackoverflow.com/questions/55106303/angular-fail-to-download-file-10mb-from-server-on-google-chrome
      .then((response) => {
        FileDownload(response, documentName);

        this.props.quotationActions.removeLastSoftAlert();
      }).catch((e) => {
      this.props.quotationActions.removeLastSoftAlert();
      toastr.error('Kan word document niet downloaden', e.message)
    });

    this.hideQuoteDownloadsDropdown();
  };

  downloadConvertedWordDocument = () => {
    const {currentQuotation} = this.props.quotation;

    this.props.wordDocumentActions.downloadConvertedWordDocument(currentQuotation.id)
      .then(() => FileDownload(this.props.wordDocuments.convertedDocument, `${currentQuotation.name}.pdf`));

    this.hideDownloadsDropdown();
  };

  getChecklist = (type) => {
    this.props.quotationActions
      .getChecklist(this.props.quotation.currentQuotation, type)
      .then(() => this.downloadChecklist());

    this.hideDownloadsDropdown();
  };

  hideDownloadsDropdown = () => {
    this.setState({
      showDownloads: false
    })
  };

  hideQuoteDownloadsDropdown = () => {
    this.setState({
      showQuoteDownloads: false
    })
  };

  downloadChecklist = () => {
    const {quotation} = this.props;
    FileDownload(
      this.props.quotation.checklist,
      'Checklist-' +
      quotation.currentQuotation.name +
      '-' +
      quotation.currentQuotation.id +
      '.xlsx',
    );
  };

  toggleDownloadBtn = () => {
    this.setState({
      showDownloads: !this.state.showDownloads
    });
  };

  toggleQuoteDownloadBtn = () => {
    this.setState({
      showQuoteDownloads: !this.state.showQuoteDownloads
    });
  };

  getDealSheet = () => {
    this.props.quotationActions
      .getDealSheet(this.props.quotation.currentQuotation)
      .then(() => this.downloadDealSheet());

    this.hideDownloadsDropdown();
  };

  downloadDealSheet = () => {
    const {currentQuotation} = this.props.quotation;
    FileDownload(this.props.quotation.dealSheet, `${currentQuotation.name}DealSheet.xlsx`);
  };

  changeInCampus = (value) => {
    this.props.discountActions.updateInCampusDiscount(this.props.quotation.currentQuotation.id, value);
  };

  closeDynamicsDialog = () => {
    this.setState({dynamicsData: {sendDynamicsDataDialog: false}});
  };

  closeEmailDialog = () => {
    this.setState({emailDialog: false})
  };

  closeEmailDialogConfirmation = () => {
    this.setState({emailDialogConfirmation: false, emailDialog: false})
  };

  closeMissingProductsDialog = () => {
    this.setState({missingProductsDialog: false});
  };

  closeMissingCWProductsDialog = () => {
    this.setState({missingCWProductsDialog: false});
  };

  submitEmailDialogConfirmation = () => {
    const quotationId = this.props.quotation.currentQuotation.id;
    const data = {
      ...this.state.emailData,
      'quotationId': quotationId,
      isSignNow: this.state.isSignNow
    };

    this.setState({emailLock: true});

    sleep(1000).then(() => {
      this.props.quotationActions.sendEmailToCustomer(quotationId, data)
          .then((response) => {
            if (response) {
              if (response.signingLog) {
                this.redirectToDigitalSigningLog(response.signingLog);
              } else {
                this.props.discountActions.getDiscountState(quotationId, this.props.editable)
              }
            }
          })
          .then(() => {
            this.closeEmailDialogConfirmation();
            this.setState({emailLock: false});
          });
    })
  };

  redirectToDigitalSigningLog = (signingLog) => {
    this.props.history.push('/offerte-ondertekenen/' + signingLog.uuid);
  };

  validateFields = (data) => {
    ['name', 'email', 'mobileNumber'].forEach(key => {
      if (data[key].length <= 0) {
        toastr.error('Naam, E-mailadres and mobiel nummer are required fields...', '');
        return false;
      }
    });

    if (data.mobileNumber.length < 10 || (data.mobileNumber.length > 2 && data.mobileNumber.slice(0, 2).toString() !== '06')) {
      toastr.error('Incorrect phone number. The number should start with 06 and have 10 digits...', '');
      return false;
    }

    return true;
  };

  onSendButton = () => {
    if (this.validateFields(this.state.emailData)) {
      this.setState({emailDialogConfirmation: true});
    }

    return false;
  };

  onChange = ({target}) => {
    let {name, value, checked} = target;

    if (name === 'mobileNumber' && value !== '') {
      value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
    }

    if (['sameAuthorizedSignatory', 'signName'].includes(name)) {
      let data = this.state.emailData;

      if (name === 'sameAuthorizedSignatory') {
        value = !checked;
      }

      data[name] = value;

      const quotationId = this.props.quotation.currentQuotation.id;

      this.props.discountActions.updateAuthorizedSignatory(quotationId, data);
    }

    this.setState(prevState => ({
      emailData: {
        ...prevState.emailData,
        [name]: value
      }
    }));
  };

  placeYielderOrder = () => {
    const {currentQuotation} = this.props.quotation;

    this.props.wordDocumentActions.downloadConvertedWordDocument(currentQuotation.id).then((response) => {
      this.props.orderingActions.sendYielderOrder(currentQuotation.id).then((response) => {
        if (response && response.success === true) {
          FileDownload(this.props.wordDocuments.convertedDocument, `${currentQuotation.name}.pdf`);
          this.setState({ placedYielderOrder: true, yielderOrderModalOpen: true})
        }
      })
    })
  }

  agreeWithTermAndConditions = ({target}) => {
    const {checked} = target;
    const {currentQuotation} = this.props.quotation;

    this.props.termsAndConditionsActions.signYielderTermsAndConditions(currentQuotation.id, checked)
        .then(() => {
          this.setState({ placedYielderOrderActive: checked, placeYielderOrder: true})
        });
  }

  handleCustomerData = (isSignNow) => {
    this.setState({emailDialog: true, isSignNow: isSignNow});

    const {isSalesValue} = this.props.authentication;
    const {currentQuotation} = this.props.quotation;

    if (isSalesValue) {
      this.props.discountActions.sendSalesValueDocument(currentQuotation.id)
        .then(() => this.props.discountActions.changeSalesValueOpportunityStatus(currentQuotation.id));
    }
  };

  openSignedDocumentDialog = () => {
    this.setState({signedDocumentDialog: true});
  }

  closeSignedDocumentDialog = () => {
    this.setState({signedDocumentDialog: false})
  }

  closeYielderOrderModal = () => {
    this.setState({yielderOrderModalOpen: false})
  }

  onSubmitSignedDocument = (e) => {
    e.preventDefault();

    const {currentQuotation} = this.props.quotation;
    let {signedDocument} = this.state;

    if (signedDocument.id) {
      this.props.signedDocumentActions.updateSignedDocument(currentQuotation.id, signedDocument).then(() => this.closeSignedDocumentDialog());
    } else {
      this.props.signedDocumentActions.addSignedDocument(currentQuotation.id, signedDocument).then(() => this.closeSignedDocumentDialog());
    }
  }

  onDownloadSignedDocument = (e) => {
    e.preventDefault();

    let {signedDocument} = this.state;

    if (signedDocument.id) {
      this.props.signedDocumentActions.downloadSignedDocument(signedDocument.id).then((response) => {
        if (response) {
          FileDownload(this.props.signedDocument.documentBlob, `${signedDocument.name}.${signedDocument.documentType}`);
        }
      });
    }
  }

  onDropSignedDocument = (files) => {
    const file = files[0];

    const {documentType} = this.state.signedDocument;

    if ((!documentType || documentType === 'docx') && file.type === 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig docx-bestand!'});
      return;
    }
    if (documentType === 'pdf' && file.type !== 'application/pdf') {
      this.setState({uploadErrors: 'Upload een geldig pdf-bestand!'});
      return;
    }

    this.setState(prevState => ({
      signedDocument: {
        ...prevState.signedDocument,
        file: file
      }
    }));
  };

  onChangeSignedDocument = ({target}) => {
    let {name, value, type, checked} = target;

    if ('checkbox' === type) {
      value = checked ? 1 : 0;
    }

    this.setState(prevState => ({
      signedDocument: {
        ...prevState.signedDocument,
        [name]: value
      }
    }));
  };

  sendDynamicsData = () => {
    const {currentQuotation} = this.props.quotation;

    this.setState({loader: true});

    this.props.quotationActions.sendDynamicsData(currentQuotation.id)
      .then(() => this.props.discountActions.getDiscountState(currentQuotation.id, this.props.editable))
      .then(() => this.setState({loader: false}))
      .then(() => {
        const {dynamicsData} = this.props.quotation;
        const sendToDynamicsFailed = dynamicsData ? dynamicsData.sendToDynamicsFailed : false;

        if (sendToDynamicsFailed) {
          this.setState({
            dynamicsData: {
              sendDynamicsDataDialog: true,
              productName: dynamicsData.product.productName
            }
          });
        }
      });
  };

  closeQuotationSentPopup = () => {
    this.setState({openDateDialog: false})
  }

  onQuotationSentAtChange = (date) => {
    this.setState({
      quotationSentAt: date,
      openDateDialog: true
    });

    this.props.quotationActions.updateOverviewData(this.props.quotation.currentQuotation.id, {
      quotationSentAt: formatDate(date)
    })
  }

  setWordFileDownloadAlert = () => {
    let alertMessage = '… een moment geduld aub. De offerte wordt gegenereerd……';
    this.props.quotationActions.addSoftAlert(alertMessage);
  };

  openMandateDialog = () => {
    this.setState({
      mandateDialog: true,
    })
  }

  closeMandateDialog = () => {
    this.setState({
      mandateDialog: false,
    })
  }

  onMandateChange = ({target}) => {
    let {name, value, type} = target;

    if ('checkbox' === type) {
      this.setState(prevState => ({
        mandateData: {
          ...prevState.mandateData,
          type: name,
        },
      }))
    }

    if ('textarea' === type) {
      this.setState(prevState => ({
        mandateData: {
          ...prevState.mandateData,
          notes: value,
        },
      }))
    }
  }

  sendMandateEmail = () => {
    const {currentQuotation} = this.props.quotation;
    this.props.quotationActions.sendMandateEmail(currentQuotation.id, this.state.mandateData)
    this.setState({
      mandateDialog: false,
      mandateData: {
        type: '',
        notes: ''
      }
    });
  }

  selectKwtCode = ({target}) => {
    const {value} = target;
    const quotationId = this.props.quotation.currentQuotation.id;
    const {provider} = this.props.quotation.currentQuotation;
    const isOdido = provider === providerType.ODIDO;
    this.props.discountActions.updateRequiredKwtOption(quotationId, {requiredKwt: value})

    const newValue = value === 'true';
    let isValid = true;

    if (value === 'false') {
      this.setState({
        kwtCode: {
          value: '',
          disabled: false,
        }
      })

      this.updateKwtCode(quotationId, '');
    }

    if (newValue && !this.state.kwtCode.value) {
      isValid = false;
    }

    this.setState({
      kwtRequiredState: newValue,
      kwtValidState: isOdido ? true : isValid
    })
  }

  openTermsAndConditionsFile = (event) => {
    event.preventDefault();
    const {currentQuotation} = this.props.quotation;

      this.props.termsAndConditionsActions.retrieveQuotationTermsAndConditionsFile(currentQuotation.id)
          .then((response) => {
            if (response) {
              let blob = new Blob([response], {type: 'application/pdf'});
              let blobUrl = URL.createObjectURL(blob);
              window.open(blobUrl, '_blank');
            }
          });

  };

  downloadQuotationDocument = (document) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    const documentId = document.id;
    const documentName = document.name;
    const documentType = document.file_name.split('.')[1];

    this.props.quotationDocumentActions.downloadQuotationDocument(quotationId, documentId).then((response) => {
      if (response === true) {
        FileDownload(this.props.quotationDocuments.documentBlob, `${documentName}.${documentType}`);
      }
    });
  };

  getUploadMenu = () => {
    const {quotationDocuments} = this.props;
    const {isYielderPartner} = this.props.authentication;
    const {provider} = this.props.quotation.currentQuotation;
    const {isVenecoOrganization} = this.props.authentication;
    const isOdido = odidoProviders.includes(provider);

    let uploadMenu = [
      {label: 'Upload KVK uittreksel', onClick: () => this.openUploadDocument('kvk')},
      {label: 'Upload ID bewijs', onClick: () => this.openUploadDocument('id-doc')}
    ];

    if (isVenecoOrganization && isOdido) {
      uploadMenu.push({label: 'Borging Odido', onClick: () => this.openUploadDocument('borging')})
    }
    if (isYielderPartner) {
      uploadMenu.push({label: 'Getekende overeenkomst uploaden', onClick: () => this.openSignedDocumentDialog()})
    }

    quotationDocuments.documents.forEach((document) => {
      uploadMenu.push({label: `Download ${document.name}`, onClick: () => this.downloadQuotationDocument(document)});
    });

    return uploadMenu;
  };

  saveQuotationDetails = (name, value) => {
    const quotationId = this.props.quotation.currentQuotation.id;
    this.props.quotationActions.saveQuotationDetails(quotationId, {notes: value});

    this.setState({
      ...this.state,
      discountNotes: value
    });
  }

  onDiscountStatusChange = ({target}) => {
    let {value} = target;

    const quotationId = this.props.quotation.currentQuotation.id;
    this.props.quotationActions.saveQuotationDetails(quotationId, {status: value});

    this.setState({
      ...this.state,
      discountStatus: value
    });
  }

  getDownloadMenu = () => {
    const {wordDocuments, authentication, quotation} = this.props;
    const {isSalesValue, isFlash, isYielderPartner} = authentication;
    const {isLead, currentQuotation} = quotation;
    const {provider} = currentQuotation;
    const allowedProvider = provider !== providerType.DBIT;

    let menuOptions = [];

    if (!isYielderPartner) {
      if (!((isSalesValue && allowedProvider) || isLead || isFlash )) {
        menuOptions.push({
          label: 'Download dealsheet',
          onClick: () => this.getDealSheet()
        });
      }
    }

    const documentType = isLead ? 'lead' : 'quotation';
    const documents = wordDocuments.documents.filter((document) => {
      return document.provider === provider && document.availabilityType === documentType && document.isQuote === false;
    });

    documents.forEach((document) => {
      menuOptions.push({
        label: `Download ${document.name}`,
        onClick: () => this.downloadWordDocument(document.id)
      })
    })

    return menuOptions;
  }

  getQuoteDocumentsMenu = (documents) => {
    let menuOptions = [];

    documents.forEach((document) => {
      menuOptions.push({
        label: `Download ${document.name}`,
        onClick: () => this.downloadQuoteWordDocument(document.id)
      })
    })

    return menuOptions;
  }

  getUploadDocumentTitle = (type) => {
    switch (type) {
      case 'kvk':
        return 'Upload KVK uittreksel';
      case 'id-doc':
        return 'Upload ID bewijs';
      case 'borging':
        return 'Borging Odido';
      default:
        return 'Upload';
    }
  }

  renderEmailDataFields = (isVenecoOrganization) => {
    const {emailData, isSignNow} = this.state;
    const authorizedSignatoryCondition = isVenecoOrganization && !isSignNow;
    const sameSignatoryLabel = 'Andere tekenbevoegde';

    return (
        <DialogBody>
          <Input
              s={12}
              value={emailData.name}
              onChange={this.onChange}
              required
              validate
              placeholder='Naam...'
              label='Naam'
              name='name'
              type='text'/>
          <Input
              s={12}
              value={emailData.email}
              onChange={this.onChange}
              required
              placeholder='E-mailadres...'
              label='E-mailadres'
              pattern="^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,9}$"
              validate
              name='email'
              type='text'/>
          <Input
              s={12}
              value={emailData.mobileNumber}
              onChange={this.onChange}
              required
              placeholder='The number should start with 06...'
              validate
              minLength="10"
              maxLength="10"
              label='Mobiel nummer'
              name='mobileNumber'
              type='text'/>
          <Input
              s={12}
              value={emailData.extraText}
              onChange={this.onChange}
              validate
              placeholder='Bijschrift e-mail...'
              label='Bijschrift e-mail'
              name='extraText'
              type='text'/>
          {
            authorizedSignatoryCondition && (
                  <div className="row">
                    <div className="col s12" style={{marginBottom: '20px'}}>
                      <input
                          id='sameAuthorizedSignatory'
                          key='sameAuthorizedSignatory'
                          name='sameAuthorizedSignatory'
                          type='checkbox'
                          checked={!emailData.sameAuthorizedSignatory}
                          onChange={this.onChange}
                      />
                      <label htmlFor="sameAuthorizedSignatory">{sameSignatoryLabel}</label>
                    </div>
                    {
                        !emailData.sameAuthorizedSignatory && (
                            <div className="input-field col s12 inner-addon">
                              <DelayInput
                                  id="signName"
                                  type="text"
                                  name="signName"
                                  delayTimeout={1000}
                                  style={{marginBottom: '20px'}}
                                  onChange={this.onChange}
                                  value={emailData.signName || ''}
                              />
                              <label htmlFor="signName">Ondertekenaarsnaam</label>
                            </div>
                        )
                    }
                  </div>
              )
          }
        </DialogBody>
    );
  }

  render() {
    const {editable, title, subTitle, discount, quotation, authentication, PreloaderParent, wordDocuments} = this.props;
    const {
      roles,
      theme,
      company,
      allowDigitalSigning,
      providers,
      isSalesValue,
      allowedDiscount,
      hasConnectWiseSync,
      downloadAccess,
      hasQuoteBtn,
      isVenecoOrganization,
      isYielderPartner
    } = authentication;

    let {isLead, currentQuotation} = quotation;
    let {
      inputsDisabled,
      inCampus,
      sentAt,
      isSentWrData,
      type,
      provider,
    } = currentQuotation;

    const isAdmin = roles.includes('ROLE_ADMIN');

    const extensionPointsSum = discount.discountTables.extensionPointsSum;
    const isExtensionPointsOn = authentication.providers[0] ? authentication.providers[0].organization.extension_points : false;
    const {discountErrors, discountTables: {totals}} = discount;

    const isWROrganization = company ? [Organization.WR, Organization.WR_TELDACOM].includes(company) : false;
    const isSVOrganization = company ? company === Organization.SV : false;

    const isVerlengenType = type === quotationType.TYPE_VERLENGEN;
    const isTMobile = [providerType.FLASH, ...odidoProviders].includes(provider);
    const isVodafoneQuotation = provider === providerType.VODAFONE;
    const isOdido = odidoProviders.includes(provider);
    const isKPN = provider === providerType.KPN;
    const isYielderQuotation = provider === providerType.YIELDER_PARTNERS;

    let organization = providers && providers.length ? providers[0].organization : null;
    let isKpnRetail = organization ? organization.name === Organization.KPN_RETAIL : false;

    let documentType = isLead ? 'lead' : 'quotation';

    const quoteDocuments = wordDocuments.documents.filter((document) => {
      return document.provider === provider && document.availabilityType === documentType && document.isQuote === true;
    });

    const offerteDocuments = wordDocuments.documents.filter((document) => {
      return document.provider === provider && document.default;
    });
    const existOfferteDocument = offerteDocuments ? offerteDocuments.length > 0 : false;

    let {
      modules,
      emailLock,
      emailDialog,
      openDateDialog,
      loading,
      emailDialogConfirmation,
      loader,
      dynamicsData,
      kwtCode,
      combinationDiscount,
      missingProductsDialog,
      missingSVProducts,
      missingConnectWiseProducts,
      missingCWProductsDialog,
      quotationSentAt,
      kwtRequiredState,
      mandateDialog,
      uploadFormOpen,
      uploadDocumentType,
      uploadDocument,
      discountNotes,
      discountStatus,
      signedDocumentDialog,
      signedDocument,
      extraKwtInputs,
      productGroups,
      yielderOrderModalOpen,
    } = this.state;

    const date = sentAt ? sentAt.toLocaleString() : null;
    const newDate = new Date(date);
    const sentToWRAt = ("0" + newDate.getDate()).slice(-2) + "-" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "-" +
      newDate.getFullYear() + " " + ("0" + newDate.getHours()).slice(-2) + ":" + ("0" + newDate.getMinutes()).slice(-2);

    const isSentToDynamicsWrData = discount.discountTables.sentToDynamics;
    const isSentMailToCustomer = discount.discountTables.isSentMailToCustomer;
    const disabledSendEmailButton = quotation.sendEmailToCustomer.success;

    let hasBussiness = false;
    productGroups.map((group) => {
      group.products.map((product) => {
        if (product.name.includes("Business")) {
          hasBussiness = true;
        }
      })
    })

    const menuItems = [
      {label: 'Direct ondertekenen', onClick: () => this.handleCustomerData(true)},
      {label: 'Verzenden naar klant', onClick: () => this.handleCustomerData(false)},
      {label: 'Getekende overeenkomst uploaden', onClick: () => this.openSignedDocumentDialog()}
    ];

    const uploadMenu = this.getUploadMenu();

    const downloadMenu = this.getDownloadMenu();

    const quoteDocumentsMenu = this.getQuoteDocumentsMenu(quoteDocuments);

    return (
      <div className="ratiotable">
        <KpnDialog
          dialogState={openDateDialog}
          modal={true}
          onRequestClose={this.closeQuotationSentPopup}
          dialogHeader={(
            <DialogHeader
              headerIcon="info"
              headerText="Let op!"
              closeHandler={this.closeQuotationSentPopup}
            />
          )}
          dialogBody={
            <DialogBody>
              <div>
                De offerte dient te worden gedownload NA het opgeven van het juiste datum van versturen. Dit om ervoor
                te zorgen dat de geldigheid van de offerte goed in de offerte staat.
              </div>
            </DialogBody>
          }
          dialogFooter={
            <DialogFooter>
              <button
                className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                type="submit"
                name="close"
                onClick={this.closeQuotationSentPopup}
              >
                Begrepen
              </button>
            </DialogFooter>
          }
        />
        {loading
          ? <LoaderComponent theme={theme}/>
          :
          <fieldset style={{border: 'none', padding: 'unset', margin: 'unset', minWidth: 0}}>
            <div id="Forms" className="paddingbottomnone">
              <h1 className="row ratio-section-title">
                <div className="col s4">
                  <i className="small material-icons left ratio-section-title">
                    playlist_add_check
                  </i>
                  <span className={'ratio-section-title'}>{title}{subTitle ? `: ${subTitle}` : ''}</span>
                  {title === 'Overzicht' && isExtensionPointsOn && !isVodafoneQuotation &&
                  <span style={{
                    marginLeft: '10px',
                    fontSize: 'small'
                  }}>Verbredingspunten: {extensionPointsSum > 0 ? '+' : ''}{extensionPointsSum}</span>
                  }
                </div>

                {editable && ProviderHelper.hasModuleEnabled(currentQuotation, 'discountSelections') ? (
                  <Col s={4} className="push-s4">
                    <div className="row" style={{marginBottom: "0"}}>
                      {!isVenecoOrganization &&
                      <>
                        {kwtCode && !kwtCode.disabled && (
                          <Input
                            s={3}
                            value={kwtCode.value}
                            type="text"
                            name="kwtCode"
                            className="without-margin-bottom"
                            onChange={event => this.onChangeKwtCode(event)}
                            label="KWT-code"
                          />
                        )}
                      </>
                      }

                      {isVenecoOrganization &&
                      <>
                        {(isKPN || isVodafoneQuotation) &&
                        <div style={{display: "flex", flexDirection: 'column'}}>
                          <div>
                            <Input
                              type="select"
                              s={3}
                              label={isKPN ? 'KWT-code' : 'Bucketcode'}
                              onChange={event => this.selectKwtCode(event)}
                              value={kwtRequiredState}
                              name="kwtOption"
                            >
                              <option key={0} value={null}>
                                Niets geselecteerd
                              </option>
                              <option key={1} value={true}>Ja</option>
                              <option key={2} value={false}>Nee</option>
                            </Input>

                            {kwtRequiredState &&
                            <Input
                              value={kwtCode.value}
                              s={3}
                              type="text"
                              name="kwtCode"
                              className="validate"
                              style={{ width: 'fit-content'}}
                              onChange={event => this.onChangeKwtCode(event)}
                              required
                            />
                            }
                          </div>

                          <div>
                            {kwtRequiredState &&
                                <div>
                                  {extraKwtInputs.map((item, index) => (
                                      <div className="input-container">
                                        <Input
                                            value={item.code}
                                            s={6}
                                            type="text"
                                            name="code"
                                            className="validate"
                                            label={isVodafoneQuotation ? 'Extra Bucketcode toevoegen' : 'Extra KWT-code toevoegen'}
                                            onChange={event => this.onChangeExtraKwtCode(event, index)}
                                            required
                                        />
                                        {index === extraKwtInputs.length - 1 && (
                                            <button style={{ backgroundColor: "transparent", border: 'none', marginTop: '10px'}} onClick={() => this.handleAddExtraInput()}>
                                              <i className="material-icons left">add_circle_outline</i>
                                            </button>
                                        )}
                                      </div>
                                  ))}
                                </div>
                            }
                          </div>
                        </div>
                        }

                        {isOdido &&
                        <div style={{display: 'flex'}}>
                          <Input
                            type="select"
                            // s={3}
                            label="KWT-code"
                            onChange={event => this.selectKwtCode(event)}
                            value={kwtRequiredState}
                            name="kwtOption"
                          >
                            <option key={0} value={''}>
                              Niets geselecteerd
                            </option>
                            <option key={1} value={true}>Ja</option>
                            <option key={2} value={false}>Nee</option>
                          </Input>

                          {kwtRequiredState &&
                          <div>
                            <p>Kortingupload in CW toevoegen.</p>
                          </div>
                          }
                        </div>
                        }
                      </>
                      }

                      <Input
                        type="select"
                        s={9}
                        label="Combinatievoordeel"
                        className="without-margin-bottom"
                        onChange={event => this.onChangeCombinationDiscount(event)}
                        value={combinationDiscount}
                        name="combinationDiscount"
                      >
                        <option key={0} value={''}>
                          Niets geselecteerd
                        </option>
                        <option key={1} value="all">Combinatievoordeel</option>
                        <option key={2} value="except-mobile">Combinatievoordeel exclusief mobiel</option>
                      </Input>
                    </div>
                  </Col>
                ) : (
                  <React.Fragment>
                    <div className="col s8" style={{marginTop: -30, width: '100%'}}>
                      <RefreshQuotation
                        quotationId={currentQuotation.id}
                        isSalesValue={isSalesValue}
                        renderAsButton={true}
                        style={inputsDisabled ? {
                          marginTop: 20,
                          marginLeft: 20,
                          backgroundColor: 'lightgray',
                          color: 'grey',
                          borderColor: 'lightgray'
                        } : {marginTop: 20, marginLeft: 20}}
                      />

                      {hasConnectWiseSync && isAdmin &&
                          <SyncConnectWiseQuotation
                              quotationId={currentQuotation.id}
                              renderAsButton={true}
                              style={inputsDisabled ? {
                                marginTop: 20,
                                marginLeft: 20,
                                backgroundColor: 'lightgray',
                                color: 'grey',
                                borderColor: 'lightgray'
                              } : {marginTop: 20, marginLeft: 20}}
                          />
                      }

                      <div className={'downloads-block'}>
                        {isSVOrganization &&
                        <div>
                          <KpnDialog
                            dialogState={missingProductsDialog}
                            dialogHeader={
                              <DialogHeader
                                headerText="Product niet gevonden in Salesforce"
                                closeHandler={this.closeMissingProductsDialog}
                              />}

                            dialogBody={
                              <DialogBody>
                                    <span>
                                      Een of meerdere producten konden niet worden gevonden in Salesforce. De opportunity is wel gesynchroniseerd, maar onderstaande producten zullen missen:
                                    </span>
                                <Table className="highlight without-margin-bottom" selectable={false}>
                                  <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                    <TableRow>
                                      <TableHeaderColumn>Productnummer</TableHeaderColumn>
                                      <TableHeaderColumn>Naam</TableHeaderColumn>
                                    </TableRow>
                                  </TableHeader>

                                  <TableBody displayRowCheckbox={false}>
                                    {missingSVProducts.map((product) =>
                                      <TableRow key={product.id} className="totals">
                                        <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                          {product.product_number}
                                        </TableRowColumn>
                                        <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                          {product.name}
                                        </TableRowColumn>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </DialogBody>
                            }
                            dialogFooter={
                              <DialogFooter
                                buttonRight={
                                  <DialogButton
                                    buttonAction={this.closeMissingProductsDialog}
                                    buttonText='Begrepen'
                                  />}
                              />
                            }
                            onRequestClose={this.closeMissingProductsDialog}
                          />
                        </div>
                        }

                        <GeneralDialog
                          dialogState={signedDocumentDialog}
                          closeHandler={this.closeSignedDocumentDialog}
                          headerText={'Ondertekend document uploaden'}
                          dialogBodyContentRaw={
                            <DialogBody>
                              <form onSubmit={this.onSubmitSignedDocument}>
                                <Row>
                                  <Input
                                    required
                                    s={12}
                                    defaultValue={signedDocument.name}
                                    onChange={this.onChangeSignedDocument}
                                    label="Naam"
                                    name='name'
                                    type='text'
                                  />
                                </Row>

                                <Row style={{marginBottom: 35}}>
                                  <Col s={12}>
                                    <Input
                                      s={12}
                                      name={'documentType'}
                                      id={'documentType'}
                                      type="select"
                                      multiple={false}
                                      required={true}
                                      label={'Type documentextensie'}
                                      defaultValue={signedDocument.documentType}
                                      onChange={this.onChangeSignedDocument}
                                      className="validate"
                                    >
                                      <option value="docx" key={'doc-docx'}>Word-documenttype (.docx)</option>
                                      <option value="pdf" key={'doc-pdf'}>PDF-documenttype (.pdf)</option>
                                    </Input>
                                  </Col>
                                </Row>

                                <Row>
                                  <Col s={12} className="input-field">
                                    <FileUpload
                                      onDrop={this.onDropSignedDocument}
                                      caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                                    />

                                    {signedDocument.file && <div>Geselecteerd bestand: {signedDocument.file.name}</div>}
                                  </Col>
                                </Row>

                                {signedDocument.id && (
                                  <button
                                    type="button"
                                    onClick={(e) => this.onDownloadSignedDocument(e)}
                                    className='btn doceri-btn-left ratio-btn-right-fullwidth left'
                                  >
                                    Ondertekend document downloaden
                                  </button>
                                )}

                                <button
                                  type="submit"
                                  onClick={(e) => this.onSubmitSignedDocument(e)}
                                  className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                                >
                                  Submit
                                </button>
                              </form>
                            </DialogBody>
                          }
                        />

                        {hasConnectWiseSync && (
                          <div>
                            <GeneralDialog
                              dialogState={missingCWProductsDialog}
                              closeHandler={this.closeMissingCWProductsDialog}
                              headerText={'Product niet gevonden in ConnectWise'}
                              dialogBodyContentRaw={(
                                <DialogBody>
                                        <span>
                                          Een of meerdere producten konden niet worden gevonden in ConnectWise. De opportunity is wel gesynchroniseerd, maar onderstaande producten zullen missen:
                                        </span>
                                  <Table className="highlight without-margin-bottom" selectable={false}>
                                    <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                                      <TableRow>
                                        <TableHeaderColumn>Productnummer</TableHeaderColumn>
                                        <TableHeaderColumn>Naam</TableHeaderColumn>
                                      </TableRow>
                                    </TableHeader>

                                    <TableBody displayRowCheckbox={false}>
                                      {missingConnectWiseProducts.map((product) =>
                                        <TableRow key={product.id} className="totals">
                                          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                            {product.product_number}
                                          </TableRowColumn>
                                          <TableRowColumn style={{whiteSpace: 'normal', wordWrap: 'break-word'}}>
                                            {product.name}
                                          </TableRowColumn>
                                        </TableRow>
                                      )}
                                    </TableBody>
                                  </Table>
                                </DialogBody>
                              )}
                              rightButtonAction={this.closeMissingCWProductsDialog}
                              rightButtonText={'Begrepen'}
                            />
                          </div>
                        )}

                        {isWROrganization && !isLead && (
                          <div>
                            <button
                              className="btn doceri-btn-right ratio-btn-white-right"
                              name="dynamics"
                              onClick={() => this.sendDynamicsData()}
                              style={{
                                marginTop: 20,
                                marginLeft: 20,
                                width: '95%',
                                display: 'flex',
                                justifyContent: 'center'
                              }}
                              disabled={!isSentToDynamicsWrData || inputsDisabled}
                            >
                              {loader ?
                                <PreloaderParent>
                                  <Preloader/>
                                </PreloaderParent> :
                                'Verzenden naar Dynamics'
                              }
                            </button>

                            <KpnDialog
                              dialogState={dynamicsData.sendDynamicsDataDialog}
                              dialogHeader={
                                <DialogHeader
                                  headerText="Missende calculatieregel"
                                  closeHandler={this.closeDynamicsDialog}
                                />}
                              dialogBody={
                                <DialogBody>
                                    <span>
                                      Voor product <b>{dynamicsData.productName}</b> is geen calculatieregel bekend. De opportunity kan niet geüpdatet worden.
                                    </span>
                                </DialogBody>
                              }
                              dialogFooter={
                                <DialogFooter
                                  buttonRight={
                                    <DialogButton
                                      buttonAction={this.closeDynamicsDialog}
                                      buttonText='Ik heb dit begrepen'
                                    />}
                                />}
                              onRequestClose={this.closeDynamicsDialog}
                            />
                          </div>)
                        }

                        <GeneralDialog
                          dialogState={emailDialog}
                          headerText={'Gegevens klant'}
                          closeHandler={this.closeEmailDialog}
                          dialogBodyContentRaw={this.renderEmailDataFields(isVenecoOrganization)}
                          rightButtonAction={this.onSendButton}
                          rightButtonText={"Verzenden"}
                        />

                        <GeneralDialog
                          dialogState={emailDialogConfirmation}
                          headerText="Weet u het zeker?"
                          dialogBodyContentRaw={(
                              <DialogBody>
                                {emailLock && (
                                <span className="progress secondaryBackgroundColor" style={{marginTop: '20px'}}>
                                  <span className="indeterminate overviewkopbggroen"/>
                                 </span>
                                )}
                              </DialogBody>
                          )}
                          closeHandler={this.closeEmailDialogConfirmation}
                          dialogFooterChildren={
                            <DialogFooter
                              buttonLeft={(
                                <DialogButton
                                  buttonAction={this.closeEmailDialogConfirmation}
                                  buttonText='Nee, ga terug'
                                  left={true}
                                />
                              )}
                              buttonRight={
                                <DialogButton
                                  buttonAction={this.submitEmailDialogConfirmation}
                                  buttonText='Ja, ik weet het zeker'
                                  buttonDisabled={this.state.emailLock}
                                />
                              }
                            />
                          }
                        />

                        {hasQuoteBtn && (
                            <DoceriDropDownMenu
                                menuItems={quoteDocumentsMenu}
                                buttonLabel={'Prijsopgave'}
                                buttonIcon={'file_download'}
                                disabled={this.state.failedApprovalQuestions.length}
                            />
                        )}

                        <DoceriDropDownMenu
                          menuItems={uploadMenu}
                          buttonLabel={'Uploads'}
                          buttonIcon={'file_upload'}
                        />

                        {allowDigitalSigning && !isLead && (
                          <DoceriDropDownMenu
                            menuItems={menuItems}
                            buttonLabel={'Digitaal tekenen'}
                            buttonIcon={'done_all'}
                            disabled={isVodafoneQuotation && hasBussiness && title === 'Kortingen'}
                          />
                        )}

                        {
                          downloadAccess && (
                              <DoceriDropDownMenu
                                menuItems={downloadMenu}
                                buttonLabel={'Downloads'}
                                buttonIcon={'file_download'}
                                disabled={this.state.failedApprovalQuestions.length}
                                />
                            )
                        }

                        {
                          uploadFormOpen &&
                          <GeneralDialog
                            dialogState={uploadFormOpen}
                            closeHandler={this.closeUploadDocumentForm}
                            headerText={this.getUploadDocumentTitle(uploadDocumentType)}
                            dialogBodyContentRaw={(
                              <DialogBody>
                                <form onSubmit={this.onSubmitUploadDocument}>
                                  <Row>
                                    <Input
                                      required
                                      s={12}
                                      defaultValue={uploadDocument.name}
                                      onChange={this.onChangeUploadDocument}
                                      label="Naam"
                                      name='name'
                                      type='text'/>
                                  </Row>

                                  <Row>
                                    <Col s={12} className="input-field">
                                      <FileUpload
                                        onDrop={this.onDropUploadDocument}
                                        caption="Sleep uw bestand hier of klik voor upload. Let op, dit overschrijft het oude bestand"
                                      />

                                      {uploadDocument.file &&
                                      <div>Geselecteerd bestand: {uploadDocument.file.name}</div>}
                                    </Col>
                                  </Row>

                                  <button
                                    className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                                    type="submit"
                                    name="action"
                                    disabled={uploadDocument.file === ''}
                                  >
                                    Submit
                                  </button>
                                </form>
                              </DialogBody>
                            )}
                          />
                        }

                        {
                          !isLead &&
                          <div style={{marginLeft: 20, display: 'grid'}}>
                            <label htmlFor={"quotationSentAt"}>Offerte verstuurd op:</label>
                            <DatePicker
                              name="quotationSentAt"
                              id="quotationSentAt"
                              selected={quotationSentAt}
                              onChange={this.onQuotationSentAtChange}
                              onChangeRaw={e => e.preventDefault()}
                              minDate={new Date()}
                              dateFormat="dd-MM-yyyy"
                              autoComplete="off"
                              required={true}
                              disabled={inputsDisabled}
                              style={{margin: 0}}
                            />
                          </div>
                        }

                        {isWROrganization && isSentWrData &&
                        <div style={{marginLeft: 20, display: 'grid'}}>
                          <label htmlFor={"sentToDynamicsAt"}>Gestuurd naar Dynamics:</label>
                          <DatePicker
                            name="sentToDynamicsAt"
                            id="sentToDynamicsAt"
                            selected={newDate}
                            dateFormat="dd-MM-yyyy HH:mm"
                            autoComplete="off"
                            required={true}
                            style={{margin: 0}}
                            disabled={inputsDisabled}
                            readOnly
                          />
                        </div>
                        }
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </h1>
            </div>

            {editable &&
            this.state.productGroups
              .filter((row) => row.products.length)
              .map((productGroup) => (
                <GroupProducts
                  key={productGroup.id}
                  productGroup={productGroup}
                  discountErrors={discountErrors}
                  editable={editable}
                  userRoles={authentication.roles}
                  isKpnRetail={isKpnRetail}
                  inCampus={inCampus}
                  changeInCampus={this.changeInCampus}
                  isVerlengenType={isVerlengenType}
                />
              ))
            }

            {!isVenecoOrganization && editable && totals && totals[0].products.length && totals[1].products.length &&
            <DiscountProduct
              productData={this.props.discountProduct}
              productsTotals={this.getProductsTotal()}
              allowedDiscount={allowedDiscount}
              inputsDisabled={inputsDisabled}
              onChangeDiscount={this.onChangeDiscount}
            />
            }

            {!editable &&
            modules
              .filter((row) => row.products.length)
              .map((module) =>
                <ModuleProducts
                  module={module}
                  isVerlengenType={isVerlengenType}
                  isVodafoneQuotation={isVodafoneQuotation}
                  inputsDisabled={inputsDisabled}
                />)}


            {isVenecoOrganization && title === 'Kortingen' &&
            (authentication.roles.includes('ROLE_ADMIN')
                || authentication.roles.includes('ROLE_SALES_SUPPORT')
                || authentication.roles.includes('ROLE_SALES')) &&
            <>
              <Col s={4} style={{position: 'absolute'}}>
                <div className="row" style={{marginBottom: "0"}}>
                  <Input
                    s={10}
                    value={discountNotes}
                    onChange={this.saveQuotationDetails}
                    validate
                    label='Notitie'
                    name='notes'
                    type='textarea'/>
                </div>
                <div className="col" style={{display: 'flex', flexDirection: 'column'}}>
                  <Input
                    name='discountStatus'
                    type='checkbox'
                    checked={discountStatus === 'approved'}
                    value='approved'
                    label='Goedgekeurd'
                    onChange={this.onDiscountStatusChange}
                  />
                  <Input
                    name='discountStatus'
                    type='checkbox'
                    checked={discountStatus === 'rejected'}
                    value='rejected'
                    label='Afgekeurd'
                    onChange={this.onDiscountStatusChange}
                  />
                  <Input
                    name='discountStatus'
                    type='checkbox'
                    checked={discountStatus === 'progress'}
                    value='progress'
                    label='In behandeling'
                    onChange={this.onDiscountStatusChange}
                  />
                </div>
              </Col>
            </>
            }

            {totals &&
            (totals[0].products.length || totals[1].products.length) ? (
              <div
                className="row"
                style={{
                  marginLeft: '-0.75rem',
                  marginRight: '-0.75rem',
                }}
              >
                <div className="col s12 m8 offset-m4">
                  <div className="row overviewkopbgtotal">
                    <div className="col s12">
                      <div className="overviewkopgroen">
                        Totalen
                        <span className="right bodynormal"/>
                      </div>
                    </div>
                  </div>
                  {totals[0].products.length ? (
                    <React.Fragment>
                      <div className="row">
                        <TotalTables
                          key={0}
                          index={0}
                          headers={this.generateTotalHeaders()}
                          rows={this.generateTotalRows(
                            'Monthly costs',
                          )}
                          title="Maandelijkse kosten"
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                  {totals[1].products.length ? (
                    <div className="row">
                      <TotalTables
                        key={1}
                        index={1}
                        headers={this.generateTotalHeaders()}
                        rows={this.generateTotalRows(
                          'One time cost',
                        )}
                        title="Eenmalige kosten"
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}

            {isVenecoOrganization && title === 'Kortingen' &&
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <button
                className="btn doceri-btn-right"
                onClick={() => this.openMandateDialog()}
                style={{position: 'relative', bottom: '-40px'}}
                type="submit"
                name="action"
              >
                Verstuur email voor aanvraag korting
              </button>
            </div>
            }

            <KpnDialog
              dialogState={mandateDialog}
              modal={true}
              onRequestClose={this.closeMandateDialog}
              dialogHeader={(
                <DialogHeader
                  headerIcon="email"
                  headerText="Aanvraag Korting"
                  closeHandler={this.closeMandateDialog}
                />

              )}
              dialogBody={
                <DialogBody>
                  <Row>
                    <Input
                      s={4}
                      type='checkbox'
                      name='resell'
                      checked={false}
                      onChange={this.onMandateChange}
                      label='Resell'
                    />
                    <Input
                      s={4}
                      type='checkbox'
                      name='yielder'
                      checked={false}
                      onChange={this.onMandateChange}
                      label='Yielder'
                    />
                  </Row>
                  <Row>
                    <Input
                      required
                      s={12}
                      onChange={this.onMandateChange}
                      label="Notitie"
                      name='notes'
                      type='textarea'/>
                  </Row>
                  <button
                    className='btn doceri-btn-right ratio-btn-right-fullwidth right'
                    type="submit"
                    name="action"
                    onClick={() => this.sendMandateEmail()}
                  >
                    Send email
                  </button>
                </DialogBody>
              }
            />

            {isYielderPartner &&
                <div>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                    <div style={{
                      border: '1px solid',
                      padding: '10px',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: 'white'
                    }}>
                      <Input
                          reverse
                          key='yielderOrder'
                          name='yielderOrder'
                          type='checkbox'
                          checked={this.state.placedYielderOrderActive}
                          onChange={this.agreeWithTermAndConditions}
                          label=' '
                      />
                      <a href="javascript:void(0)" onClick={this.openTermsAndConditionsFile} target="_blank">Algemene
                        voorwaarden</a>
                    </div>
                  </div>

                  <div style={{
                    padding: '10px',
                    borderRadius: '5px',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end'

                  }}>
                    <button
                        className='btn doceri-btn-right right'
                        type="submit"
                        name="yielderOrder"
                        disabled={this.state.placedYielderOrderActive ? this.state.placedYielderOrder ?? false : true}
                        onClick={this.placeYielderOrder}
                    >
                      Plaats bestelling
                    </button>

                  </div>

                </div>
            }

            <GeneralDialog
                dialogState={yielderOrderModalOpen}
                closeHandler={this.closeYielderOrderModal}
                headerText={'Bestelling bevestigd'}
                dialogBodyContentRaw={
                  <DialogBody>
                    <div>
                      <p>De bestelling is succesvol geplaatst</p>
                    </div>
                  </DialogBody>
                }
            />

          </fieldset>
        }
      </div>
    );
  }
}

const mapStateToProps = ({
   quotation,
   discount,
   authentication,
   wordDocuments,
   discountProduct,
   alerts,
   steps,
   quotationDocuments,
   signedDocument
 }) => {
  return {
    quotation,
    discount,
    authentication,
    wordDocuments,
    discountProduct,
    alerts,
    availableToGoForward: steps.availableToGoForward,
    quotationDocuments,
    signedDocument
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    discountActions: bindActionCreators(discountActions, dispatch),
    quotationActions: bindActionCreators(quotationActions, dispatch),
    wordDocumentActions: bindActionCreators(wordDocumentActions, dispatch),
    quotationDocumentActions: bindActionCreators(quotationDocumentActions, dispatch),
    discountProductActions: bindActionCreators(discountProductActions, dispatch),
    stepsActions: bindActionCreators(stepsActions, dispatch),
    signedDocumentActions: bindActionCreators(signedDocumentActions, dispatch),
    termsAndConditionsActions: bindActionCreators(termsAndConditionsActions, dispatch),
    orderingActions: bindActionCreators(orderingActions, dispatch)
  };
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DiscountOverview));
