import React, {Component} from 'react';

class DigitalSigningConfirmation extends Component {
  renderDefaultMessage = () => {
    return (
        <div className={'Forms digitalSigningForm'}>
          <div>
            <h4 style={{paddingBottom: '2rem'}}>
              <b>
                Geachte klant,
              </b>
            </h4>
          </div>

          <div className="row">
            <span>
              Bedankt voor het akkoord gaan met de offerte.
            </span>
          </div>

          <div className="row">
            <span>
              U ontvangt binnen enkele ogenblikken het getekende document per e-mail.
            </span>
          </div>

          <div className="row">
            <span>
              Mocht u nog vragen hebben, kunt u contact opnemen met uw contactpersoon.
            </span>
          </div>

          <div className="row">
            <span>
              Met vriendelijke groet,
            </span>
          </div>
          <div className="row">
            <img src={this.props.theme.logo} className="responsive-img app-header-logo-digital-signing" alt=""/>
          </div>
        </div>
    );
  };

  renderVenecoEnvironmentMessage = () => {
    return (
        <div className={'Forms digitalSigningForm'}>
          <div>
            <h4 style={{paddingBottom: '2rem'}}>
              <b>
                Beste {this.props.data.contactPersonName},
              </b>
            </h4>
          </div>

          <div className="row">
            <span>
             Bedankt voor het akkoord gaan met de offerte. Je ontvangt het getekende document binnen enkele ogenblikken per e-mail.
            </span>
          </div>

          <div className="row">
            <span>
              Mocht je nog vragen hebben, neem dan gerust contact op met je contactpersoon.
            </span>
          </div>

          <div className="row">
                <span>
                  Met vriendelijke groet,
                </span>
          </div>
          <div className="row">
                <span>
                  Venéco bij Doceri
                </span>
          </div>
          <div className="row">
            <img src={this.props.theme.logo} className="responsive-img app-header-logo-digital-signing" alt=""/>
          </div>
        </div>
    );
  }

  render() {
    return (
        <fieldset style={{border: 'none', padding: 'unset', margin: 'unset'}}>
          {this.props.data.isVeneco ? this.renderVenecoEnvironmentMessage() : this.renderDefaultMessage()}
        </fieldset>
    );
  }
}

export default DigitalSigningConfirmation;
