import { actionTypes } from '../constants/actionTypes';
import { apiRoutes } from '../constants/apiRoutes';

import RequestType from "../constants/requestType";
import ApiService from "../services/apiService";

const getEmailTemplates = () => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.emailTemplates.getEmailTemplates;
            const response = await ApiService.performRequest(RequestType.GET, url);

            dispatch(_getEmailTemplatesSuccess(response))
        }
        catch (error) {
            return {error}
        }
    }
};

const _getEmailTemplatesSuccess = (response) => {
    return {
        type: actionTypes.getEmailTemplates,
        response
    }
};

const updateEmailTemplate = (body) => {
    return async (dispatch) => {
        try {
            let url = apiRoutes.emailTemplates.updateEmailTemplate.replace('{emailTemplate}', body.id);
            const response = await ApiService.performRequest(RequestType.POST, url, body);

            if(response['success']) {
                dispatch(_updateEmailTemplatesSuccess(response['templates']))
            }
        }
        catch (error) {
            return {error}
        }
    }
}

const _updateEmailTemplatesSuccess = (templates) => {
    return {
        type: actionTypes.updateEmailTemplate,
        templates: templates
    }
}

export default {
    getEmailTemplates,
    updateEmailTemplate
};
