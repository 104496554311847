const apiRoutes = {
    rootUrl: process.env.REACT_APP_API_URL || 'https://kpn.doceri-dev.nl/index.php/api/',
    authenticationRelated: {
        login: 'login',
        getToken: 'login_check',
        info: 'user/info',
        organizationInfo: 'organization/info/{domain}',
        updateTheme: 'user/updateStyling',
        getSwitchableOrganizations: 'organization/switchable/list',
        switchOrganization: 'user/switch/organization',
        userResetOrganization: 'user/reset/organization'
    },
    customersRelated: {
        findCustomer: 'customer/find/{quotationId}',
        doSecondaryIrmaSearch: 'customer/secondaryIrmaSearch/{quotationId}',
        findLeadCustomer: 'customer/lead/find/{leadId}',
        createCustomer: 'customer',
        createLeadCustomer: 'customer/lead',
        initialPrequal: 'customer/executePrequal/{quotationId}',
        retrieveExistingData: 'customer/retrieveExistingData/{quotationId}',
        updateCustomer: 'quotation/{quotationId}/customer/',
        updateLeadCustomer: 'lead/{quotationId}/customer/',
        updateIrmaID: 'quotation/{quotationId}/customerIrmaId/{customerId}',
        updateOdidoBillingAccountCode: 'customer/{customer}/update-odido-billing-code',
        updateYipCustomerNumber: 'customer/{customer}/update-yip-customer-number',
        updateEnreachAccountId: 'customer/{customer}/update-enreach-account-id',
        getQuotationsByKvkIrmaIds: 'customer/{quotation}/get-existing-quotations',
    },
    locationsRelated: {
        getLocations: 'locationList',
        getLeadLocations: 'locationList/lead',
        location: 'location',
        leadLocation: 'location/lead',
        locationBulk: 'location/bulk/',
        postalCodeCheck: 'location/postalCode',
        updateLocation: 'location/',
        updateLeadLocation: 'location/lead/',
        israCheck: 'location/israCheck',
    },
    productsRelated: {
        getAllProducts: 'products/{providerID}/retrieve-all-products',
        getProductDetails: 'products/{productID}/retrieve-product-details',
        updateProductDetails: 'products/{productID}/update-product-details',
        getProductOdidoDetails: 'products/{productID}/retrieve-product-odido-details',
        getExtendedProductOdidoDetails: 'products/{productOdidoDetailsID}/retrieve-product-odido-details-extended',
        updateProductOdidoDetails: 'products/{productOdidoDetailsID}/update-product-odido-details',
        updateProductOdidoPrice: 'products/{productOdidoPriceID}/update-product-odido-price'
    },
    hardwareRelated: {
        addHardwareOption: 'hardware/add-new-hardware/{providerId}',
        getAllHardware: 'hardware/{providerId}',
        getAllProductsToLink: 'hardware/products-to-link/{providerId}',
        getHardwareOptionCategories: 'hardware/options/categories',
        hardwareBulk: 'hardware/bulk/{providerId}/{inactive}/{copy}',
        updateHardwareOption: 'hardware/update-hardware-option/{hardwareId}',
        updateHardware: 'hardware',
        removeHardwareOption: 'hardware/{hardwareId}',
        removeHardware: 'hardware',
        getHardware: 'hardware/{id}',
        getImportXls: 'hardware/hardware-import-template.xlsx',
        getAssortimentXls: 'hardware/{providerId}/hardware-assortiment.xlsx',
        getCustomHardwareOptions: 'hardware/options/{type}/{id}',
        getCustomHardwareOptionsSelections: 'hardware/options/selections/{quotationId}/{locationId}/{type}',
        getAllCustomHardwareOptionsSelections: 'hardware/allSelections/{quotationId}',
        persistCustomHardwareSelections: 'hardware/options/persist/{type}/{locationId}',
        retrieveExistingVoiceData: 'hardware/retrieveExistingData/{quotationId}',
        retrieveExistingTelephoneExchange: 'hardware/retrieveExistingTelephoneExchange/{quotationId}',
        updateExistingTelephoneExchangeOrderId: 'hardware/updateExistingTelephoneExchangeOrderId/{quotationId}',
    },
    partnerServicesRelated: {
        productsOverview: 'products-overview/{active}/{providerId}',
        treeData: 'partners-services/tree-data/{productId}',
        addPartnerService: 'partners-services/add-new-service/{providerId}',
        getAllPartnerServices: 'partners-services/{providerId}',
        getAllProductsToLink: 'partners-services/products-to-link/{providerId}/{quotationId}',
        getServiceOptionCategories: 'partners-services/options/categories',
        partnerServicesBulk: 'partners-services/bulk/{providerId}/{inactive}/{copy}',
        updatePartnerService: 'partners-services/update-partner-service/{serviceId}',
        patchPartnerService: 'partners-services/{serviceId}',
        duplicatePartnerService: 'partners-services/duplicate-service/{serviceId}/{keepRelations}/{keepActive}',
        removePartnerServices: 'partners-services',
        removePartnerService: 'partners-service/{serviceId}',
        getPartnerServices: 'partners-services/{id}',
        getImportXls: 'partners-services/partners-services-import-template.xlsx',
        getAssortimentXls: 'partners-services/{providerId}/partner-services-assortiment.xlsx',
        getPartnerServicesOptions: 'partners-services/partner/{quotationId}',
        getITServicesOptions: 'partners-services/it/{quotationId}',
        persistPartnerITServicesSelections: 'partners-services/persist/{type}',
        getPartnerServicesOptionsForQuotation: 'partners-services/options/{quotationId}/{type}',
        getMbkProducts: 'partners-services/mbk-products/{quotationId}',
        updateMbkProducts: 'partners-services/{quotationId}/patch-mkb-products',
        updateMbkOptions: 'partners-services/{quotationId}/patch-mkb-options',
        getSecurityOptions: 'partners-services/{quotationId}/get-security-options',
        patchSecurityOptions: 'partners-services/{quotationId}/patch-security-options',
    },
    leadRelated: {
        createNew: 'lead',
        getAll: 'leadList',
        get: 'lead',
        deleteLead: 'lead',
        updateLead: 'lead/',
        patchLead: 'lead/{leadId}',
        takeOver: 'lead/takeover/',
        assignProjectManager: 'lead/assignProjectManager/',
        getProducts: 'lead/products/',
        getSelections: 'lead/selections/',
        updateSelections: 'lead/selections/',
        setExcludeRelationsProducts: 'lead/{lead}/exclude-relations-products'
    },
    quotationQuestionsRelated: {
        getQuestions: 'quotation-questions/{quotationId}/{module}/{locationId}',
        getQuestionSelections: 'quotation-questions/selections/{quotationId}/{module}/{locationId}',
        updateQuestionSelections: 'quotation-questions/selections/{quotationId}/{module}/{locationId}',
    },
    quotationRelated: {
        createNew: 'quotation',
        getAll: 'quotationList',
        get: 'quotation',
        deleteQuotation: 'quotation',
        updateQuotation: 'quotation/',
        patchQuotation: 'quotation/{quotationId}',
        duplicateQuotation: 'quotation/{quotationId}/duplicate',
        getXML: 'quotation/xml/',
        getMobileBulkXls: 'quotation/{quotationId}/quotation-mobile-bulk-template.xlsx',
        getVoiceBulkXls: 'quotation/{quotationId}/quotation-voice-bulk-template.xlsx',
        getLocationBulkXlsx: 'quotation/{quotation}/quotation-location-bulk-template.xlsx',
        getDealSheet: 'quotation/dealSheet/{quotationId}',
        getChecklist: 'quotation/checklist/',
        getLocationsXls: 'quotation/{quotationId}/locations.xls',
        takeOver: 'quotation/takeover/',
        assignProjectManager: 'quotation/assignProjectManager/',
        startOrdering: 'quotation/ordering/{quotationId}',
        getChanges: 'quotation/{quotationId}/changes',
        getPresetDiscountChanges: 'quotation/{quotationId}/discount-preset-changes',
        getPresetDiscountCheck: 'quotation/{quotationId}/discount-preset-check',
        syncOpportunityEmail: 'quotation/{quotation}/sync-opportunity-email',
        updateVoiceProduct: 'quotation/{quotationId}/updateVoiceProduct',
        getVoiceProducts: 'quotation/{quotationId}/getVoiceProducts',
        updateAccessProduct: 'quotation/{quotationId}/updateAccessProduct',
        getAccessProducts: 'quotation/{quotationId}/getAccessProducts',
        updateNumber: 'quotation/{quotationId}/updateNumber',
        getPhoneNumbers: 'quotation/{quotationId}/phoneNumbers',
        getUpgradingData: 'quotation/getUpgradingData/{quotationId}',
        initializeQuotation: 'quotation/{quotationId}/initialize',
        sendDynamicsData: 'quotation/{quotationId}/sendDynamicsData',
        sendEmail: 'quotation/{quotationId}/send-email-to-customer',
        getCustomerData: 'quotation/{quotation}/{opportunityNumber}/get-customer-data',
        getQuotationAutotaskData: 'quotation/{quotation}/{opportunityId}/get-quotation-autotask-data',
        updateOverviewData: 'quotation/{quotationId}/update-overview-data',
        setConnectionsToVerlengen: 'quotation/{quotation}/set-connections-to-verlengen',
        setDesiredApplicationSystem: 'quotation/{quotation}/set-desired-application-system',
        getApplicationData: 'quotation/{quotation}/get-application-data',
        setFlashRequiredWindowsApplicationProducts: 'quotation/{quotation}/update-flash-products',
        addFlashProductQuantity: 'quotation/{quotation}/add-product-quantity',
        updateHardwareProducts: 'quotation/{quotation}/update-hardware-products',
        getInactiveCustomProducts: 'quotation/{quotation}/get-inactive-custom-products',
        updateInactiveCustomProducts: 'quotation/{quotation}/update-inactive-custom-products',
        checkQuestionsApproval: 'quotation-check/{quotation}/check-questions-approval',
        sendMandateEmail: 'quotation/{quotationId}/mandate/send-email',
        saveQuotationDetails: 'quotation/{quotationId}/quotation-details',
        resyncConnectWise: 'quotation/{quotationId}/resync-connectwise'
    },
    organizationRelated: {
        getAll: 'organizationList',
        getOrganization: 'organization',
        editOrganization: 'organizations/{organizationId}',
    },
    tagRelated: {
        getAll: 'tagList',
        patchTag: 'custom-tag/{tagId}',
        addTag: 'custom-tag',
        getCategoriesToLink: 'tag-categories-to-link'
    },
    tagCategoryRelated: {
        getAll: 'tagCategoryList',
        patchTagCategory: 'custom-tag-categories/{categoryId}',
        addTagCategory: 'custom-tag-categories'
    },
    postcodeRelated: {
        getAll: 'postcodeList',
        patchPostcode: 'postcode/{postcodeId}',
        addPostcode: 'add-postcode',
        getCategoriesToLink: 'postcode-categories-to-link',
        deletePostcode: 'postcode/{postcodeId}'
    },
    postcodeCategoryRelated: {
        getAll: 'postcodeCategoryList',
        patchPostcodeCategory: 'postcode-categories/{categoryId}',
        addPostcodeCategory: 'postcode-categories',
        deletePostcodeCategory: 'postcode-categories/{categoryId}'
    },
    discountProductRelated: {
        getDiscountProduct: 'discount-product/{quotationId}',
        editDiscountProduct: 'discount-product/{quotationId}',
    },
    adminRelated: {
        getUsers: 'userList',
        compensation: 'compensation',
        updateCompensation: 'compensation/{id}',
        user: 'user',
        userToAssign: 'user/assign/{userToReplace}/to/{userToAssign}',
        createUser: 'users',
        editUser: 'users/{userId}',
        hasQuotationData: 'user/hasQuotationData/{userId}',
        updateFlashCalculation:'flashCalculation/{flashCalculationId}',
        getFlashCalculations: 'flashCalculations',
        enableOrganizationTermsAndConditions: 'terms-and-conditions/enable-terms-and-conditions',
        addOrganizationTermsAndConditionFile: 'terms-and-conditions/add-terms-and-conditions-file',
        editOrganizationTermsAndConditionsFile: 'terms-and-conditions/{termsAndConditions}/edit-terms-and-conditions-file',
        downloadTermsAndConditionsFile:'terms-and-conditions/{termsAndConditions}/download',
        getTermsAndConditionsFiles: 'terms-and-conditions/get-organization-terms-and-conditions-files/{providerId}',
        deleteTermsAndConditionsFile: 'terms-and-conditions/{termsAndConditions}',
        getTermsAndConditionsProductCategories: 'terms-and-conditions/productCategories',
    },
    attachmentsRelated: {
      getAttachments: 'attachments/{providerId}/getAttachments',
      deleteAttachments: 'attachments/{attachment}/delete',
      addAttachment: 'attachments/addAttachment',
      updateAttachment: 'attachments/{attachment}/updateAttachment',
      downloadAttachment: 'attachments/{attachmentID}/download',
      getAttachmentProductCategories: 'attachments/productCategories',
    },
    wordDocumentRelated: {
        getWordDocuments: 'word-document/{providerId}',
        getWebLeadWordDocuments: 'word-document/webLead/{providerId}',
        addWordDocument: 'word-document',
        update: 'word-document/{documentId}',
        download: 'word-document/{documentId}/download',
        generate: 'word-document/{documentId}/generate/{quotationId}',
        generateLeadDocument: 'word-document/{documentId}/generate/lead/{leadId}',
        downloadConvertedWordDocument: 'word-document/{quotation}/download-converted-document',
        generateQuote: 'word-document/{documentId}/generate/quote/{quotationId}',
    },
    quotationDocumentRelated: {
        getQuotationDocuments: 'quotation-document/{quotationId}/list',
        addQuotationDocument: 'quotation-document/{quotationId}/upload',
        downloadQuotationDocument: 'quotation-document/{quotationId}/{quotationDocumentId}/download'
    },
    signedDocumentRelated: {
        getSignedDocument: 'signedDocument/{quotationId}/documentData',
        addSignedDocument: 'signedDocument/{quotationId}/addSignedDocument',
        downloadSignedDocument: 'signedDocument/{signedDocumentId}/download',
        deleteSignedDocument: 'signedDocument/{signedDocumentId}/delete',
        updateSignedDocument: 'signedDocument/{quotationId}/updateSignedDocument'
    },
    organizationProvidersLogosRelated: {
        getLogos: 'organization-provider-logos/{providerId}',
        addLogoFile: 'organization-provider-logos/add',
        update: 'organization-provider-logos/{organizationProviderLogo}',
        download: 'organization-provider-logos/{organizationProviderLogo}/download'
    },
    downloadsRelated: {
        getDownloads: 'downloads',
        download: 'downloads/{documentId}/download',
        update: 'downloads/{documentId}',
    },
    digitalSigningGroupsRelated: {
        getAll: 'digital-signing-groups',
        addDigitalSigningGroup: 'digital-signing-groups',
        patchDigitalSigningGroup: 'digital-signing-groups/{group}',
        deleteDigitalSigningGroup: 'digital-signing-groups/{group}',
        sortDigitalSigningGroup: 'digital-signing-groups/sortGroups',
    },
    digitalSigningDetails: {
        getDigitalSigningDetails: 'digital-signing-details',
        patchDigitalSigningDetails: 'digital-signing-details',
    },
    digitalSigningFieldRelated: {
        getAll: 'digital-signing-fields/{quotation}',
        updateDigitalSigningField: 'digital-signing-fields/{field}',
        addDigitalSigningField: 'digital-signing-fields',
        deleteDigitalSigningField: 'digital-signing-fields/{field}',
        sortDigitalSigningField: 'digital-signing-fields/sortFields',
    },
    digitalSigningLogsRelated: {
        getDigitalSigningLog: 'public/digital-signing-log/{uuid}',
        saveSignature: 'public/digital-signing-log/{uuid}/save-signature',
        sendSmsCode: 'public/digital-signing-log/{uuid}/send-sms-code',
        confirmSmsCode: 'public/digital-signing-log/{uuid}/confirm-sms-code',
        downloadPDFDocument: 'public/digital-signing-log/{uuid}/download-pdf-document/{type}/{documentId}',
        downloadAttachmentDocument: 'public/digital-signing-log/{uuid}/download-attachment-document/{documentId}',
        retrieveAttachmentDocuments: 'public/digital-signing-log/{uuid}/retrieve-attachment-documents',
        patchDigitalSigningField: 'public/digital-signing-log/{uuid}/digital-signing-field/{field}',
        sendConfirmationMail: 'public/digital-signing-log/{uuid}/send-confirmation-mail',
        sendPdfDocuments: 'public/digital-signing-log/{uuid}/send-pdf-documents',
        retrieveTermsAndConditionsFile: 'public/digital-signing-log/{uuid}/retrieve-terms-conditions/{tcFileId}',
        retrieveQuotationTermsAndConditionsFile: 'public/quotation/{quotationId}/retrieve-terms-conditions',
        retrieveTermsAndConditionsFiles: 'public/digital-signing-log/{uuid}/retrieve-terms-conditions',
        signTermsAndConditions: 'public/terms-and-conditions/sign-terms-and-conditions',
        signYielderTermsAndConditions: 'public/terms-and-conditions/sign-yielder-terms-and-conditions'
    },
    connectionsRelated: {
        getConnectionsPerLocation: 'connection/availability/',
        getConnectionsListPerLocation: 'connection/list/',
        deleteConnection: 'connection/',
        createConnection: 'connection',
        updateConnection: 'connection/',
        connectionAllowed: 'connection/{connectionId}/{productId}/validate',
        checkConnectionTypeRequired: 'connection/{connectionId}/{productId}/requires-connection-type',
        checkUpgradeTypeRequired: 'connection/requires-upgrade-type',
        addConnectionSelection: 'connection/{connection}/selection',
        addKortingMonths: 'connection/{connection}/add-korting-months',
        updateConnectionType: 'connection/{connection}/update-connection-type',
        setOneTimeCostZero: 'connection/{connection}/set-one-time-cost',
        getFtthConnectionCheck: 'connection/ftthConnectionCheck/{connection}',
    },
    accessRelated: {
        get: 'module/access/',
        getOptions: 'module/access/options/',
        getOrganizationHip: 'module/access/organization/{quotationId}',
        retrieveExistingAccessData: 'module/access/retrieveExistingData/{quotationId}',
        updateAccessData: 'module/access/updateAccessData/{quotationId}',
        updatePremiumWifiSelections: 'quotation/{quotationId}/updatePremiumWifiSelections',
        updateFortiGateSelections: 'quotation/{quotationId}/updateFortiGateSelections',
        setLocationHip: 'module/access/{quotationId}/location/{locationId}/availability/{availability}',
        changeAccessType: 'module/access/changeAccessType/{locationId}',
        getSdWanProducts: 'module/access/getSdWanProducts/{locationId}',
        updateSdWanLocationProduct: 'module/access/updateSdWanLocationProduct/{locationId}',
        updateLocationSdWanProperty: 'module/access/updateLocationSdWanProperty/{locationId}',
        updateSdWanSipTrunk: 'module/access/updateSdWanSipTrunk/{sipTrunkId}',
        getLocationSdWanOptions: 'module/access/sdWanOptions/{locationId}',
        getUpgradeAccessData: 'module/access/getUpgradeAccessData/{quotationId}',
        hasPendingUpgradeData: 'module/access/hasPendingUpgradeData/{quotationId}',
    },
    discountRelated: {
        getOverview: 'overview/',
        getLeadOverview: 'lead/overview/',
        syncSalesForceOpportunity: 'quotation/{quotation}/sync-sales-force-opportunity',
        getSalesValueOpportunityValues: 'quotation/{quotation}/check-opportunity-values',
        sendSalesValueDocument: 'quotation/{quotation}/send-salesvalue-document',
        changeSalesValueOpportunityStatus: 'quotation/{quotation}/change-opportunity-status',
        getDiscounts: 'overview/{quotationId}/discount',
        getLeadDiscounts: 'lead/overview/{leadId}/discount',
        update: 'discount/{quotationId}/discount-clusters/{offerId}',
        updateLeadDiscount: 'discount/lead/{leadId}/discount-clusters/{offerId}',
        updateKwtCode: 'kwt-code/{quotationId}',
        updateRequiredKwtCode: 'kwt-code/required/{quotationId}',
        updateCombinationDiscount: 'combination-discount/{quotationId}',
        updateInCampus: 'in-campus-discount/{quotationId}',
        updateSmartMigrationDiscount: 'smart-migration-discount/{connectionId}',
        updateNtDependency: 'nt-dependency/{connectionId}',
        getSmartMigrationDiscount: 'smart-migration-discount/{connectionId}',
        getNtDependency: 'nt-dependency/{connectionId}',
        getNtAllowed: 'nt-dependency-allowed/{connectionId}',
        savePrice: 'quotation/{quotation}/save-price',
        connectWiseMissingProducts: 'quotation/{quotation}/check-connect-wise-products',
        updateAuthorizedSignatory: 'quotation/{quotation}/update-authorized-signatory'
    },
    orderingRelated: {
        get: 'ordering/{quotationId}',
        selectedProducts: 'ordering/{quotationId}/selectedProducts',
        getOrdersContractDates: 'ordering/{quotationId}/contractEndDates',
        update: 'ordering/{quotationId}/update',
        updateProducts: 'ordering/{quotationId}/updateProducts',
        send: 'ordering/{quotationId}/send',
        sendYielderOrder: 'ordering/{quotationId}/send-yielder-order',
        getFixedTelephoneNumber: 'ordering/fixedTelephoneNumber/{telephoneNumberId}/{numberText}',
        getMobileTelephoneNumber: 'ordering/mobileTelephoneNumber/{telephoneNumberId}',
        getPortingDetails: 'ordering/portingDetails/{telephoneNumberId}',
        updatePortingDetails: 'ordering/portingDetails/{telephoneNumberId}',
        getPortingOperatorAndProvider: 'ordering/portingDetails/{quotationId}/{portingDetailsId}/get-operator-and-provider',
        getTelephoneNumberForBulk: 'ordering/telephoneNumber/{quotation}/{action}',
        patchBulkTelephoneNumbers: 'ordering/telephoneNumbers/{telephoneNumber}',
        getVamoProductSelection: 'ordering/{quotation}/{product}/vamo-product-selection',
        saveVamoProductData: 'ordering/{quotation}/save-vamo-product-data',
        updateTelephoneNumber: 'ordering/telephoneNumber/{telephoneNumberId}',
        createTelephoneNumber: 'ordering/{quotationId}/telephoneNumber',
        getHostedVoiceNumber: 'ordering/hostedVoiceNumber/{hostedVoiceNumberId}',
        getAccessOptions: 'ordering/accessOptions/{selectionId}',
        saveAccessOptions: 'ordering/accessOptions/{optionsId}',
        saveMatchingAddons: 'ordering/{quotationId}/saveMatchingAddons',
        getQuotationOrderXml: 'ordering/{quotation}/get-order-xml',
        saveOrderingProductComment: 'ordering/{orderingSentProductId}/save-ordering-comment',
        setOrderingXdslProductsOutletRequired: 'ordering/{quotation}/set-ordering-bulk-data',
        getNetworkData: 'ordering/mobile-networks/{quotation}',
        checkOdidoOrderingData: 'ordering/check-odido-ordering-data/{quotation}',
        resetUserPasswords: 'ordering/{quotation}/phone-user-reset',
    },
    fileRelated: {
        get: 'file',
    },
    passwordResetRelated: {
        requestPasswordReset: 'password-reset/request-reset',
    },
    hostedVoiceRelated: {
        patch: 'quotation/{quotationId}/{locationId}/hosted-voice',
        hardware: 'hosted-voice/hardware',
        patchLocationCallChargeType: 'location/{locationId}/callChargeType',
        postSipTrunk: 'location/{locationId}/siptrunk',
        pathSipTrunk: 'location/{locationId}/siptrunk/{sipTrunkId}',
        deleteSipTrunk: 'location/{locationId}/siptrunk/{sipTrunkId}',
        getLocationSipTrunks: 'location/{locationId}/siptrunk',
        removeHostedVoiceNumber: 'quotation/{quotationId}/{locationId}/remove-hosted-voice-number',
        getVamoVoiceProductsCount: 'quotation/{quotationId}/get-vamo-voice-products',

        // ucc related
        getUccOptions: 'hosted-voice/{quotationId}/ucc',
        patchUccOptions: 'hosted-voice/{quotationId}/ucc',
        getUccSelections: 'hosted-voice/{quotationId}/ucc-selections',
        patchUccProfiles: 'hosted-voice/{quotationId}/ucc-profiles',
        patchTelephoneNumbers: 'hosted-voice/{quotationId}/ucc-telephone-numbers',
    },
    telephonyRelated: {
        patchTelephonyOptions: 'quotation/{quotationId}/telephony',
        getTelephonyProducts: 'telephony/{quotationId}/{locationId}/offer-products',
        patchOfferProducts: 'telephony/{quotationId}/{locationId}/offer-products',
        getTelephonyLocationOptions: 'telephony/{quotationId}/{locationId}/telephony-location-options',
        patchTelephonyLocationOptions: 'telephony/{quotationId}/{locationId}/update-location-options',
    },
    communicationRelated: {
        patchCommunicationOptions: 'quotation/{quotationId}/communication',
    },
    businessConnectionRelated: {
        patch: 'location/{locationId}/business-connect',
    },
    mobileRelated: {
        putQuotation: 'quotation/{quotationId}/mobile',
        getOfferYears: 'mobile/offer-years/{quotationId}',
        patchOfferProducts: 'mobile/{quotationId}/{locationId}/offer-products',
        getProductsByOfferYear: 'mobile/{quotationId}/{locationId}/offer-products/{year}',
        deleteMobileOfferProducts: 'mobile/{quotationId}/offer-products',
        getMobileBulkXlsExample: 'mobile/{quotationId}/{locationId}/{existing}/quotation-mobile-bulk-template.xlsx',
        getMobileEmptyBulkXlsExample: 'mobile/{quotationId}/{locationId}/{existing}/quotation-mobile-empty-bulk-template.xlsx',
        deleteAllMobileOptions: 'mobile/{quotationId}/{locationId}/{fileType}/{deleteAllMobileOptions}',
        disableMobileSelections: 'mobile/disableSelections/{quotationId}',
        retrieveExistingMobileData: 'mobile/retrieveExistingData/{quotationId}',
        resetExistingMobileData: 'mobile/resetExistingData/{quotationId}',
        getUpgradingMobileData: 'mobile/getUpgradingInfo/{quotationId}/{orderId}/{phoneNumber}',
        getUpgradeMobileData: 'mobile/getUpgradeMobileData/{quotationId}',
        hasPendingUpgradeData: 'mobile/hasPendingUpgradeData/{quotationId}',
        deleteTemporaryData: 'mobile/{quotationId}/deleteTemporaryData',
        patchMobileAddons: 'mobile/{quotationId}/{locationId}/patch-addons',
        updateEndDateOptions: 'mobile/{quotationId}/{locationId}/end-date-options',
        getExceptionEndDateProducts: 'mobile/exception-end-date-products',
        getQuotationOperators: 'mobile/{quotationId}/operators',
        updateQuotationOperators: 'mobile/{quotationId}/update-operators',
        getAvailableTelephoneNumbers: 'mobile/{quotationId}/available-numbers'
    },
    isdnMigrationRelated: {
        getOffers: 'isdn-migration/{locationId}/offers',
        getOffer: 'isdn-migration/{locationId}/offer/{offerId}',
        getQuotationOptions: 'quotation/{quotationId}/options',
        patchQuotationOptions: 'quotation/{quotationId}/options',
        getConnectionOptions: 'connection/{connectionId}/options',
        getLocationOptions: 'location/{locationId}/options',
        patchLocationOptions: 'isdn-migration/location/{locationId}/options',
        patchConnection: 'isdn-migration/connection/{connectionId}',
        patchConnectionOptions: 'connection/{connectionId}/options',
        patchCallChargeType: 'isdn-migration/quotation/{quotationId}/hosted-voice-options',
        patchHostedVoiceNumber: 'isdn-migration/connection/{connectionId}/number/{id}',
        addHostedVoiceNumber: 'isdn-migration/connection/{id}/number',
        deleteHostedVoiceNumber: 'isdn-migration/connection/{connectionId}/number/{id}'
    },
    AKBRelated: {
        uploadPhoneNumbers: 'akb/{quotationId}/upload',
        patchTelephoneNumber: 'akb/patch/{telephoneNumberId}/{quotationId}/{productId}',
        addTelephoneNumber: 'akb/add/{quotationId}/{locationId}',
        addTelephoneNumbersForEachProfileSelected: 'akb/add/{quotationId}/{locationId}/profiles-selected',
        getTelephoneNumbers: 'akb/{quotationId}',
        deleteTelephoneNumber: 'akb/delete/{telephoneNumberId}',
        patchMobileSettings: 'akb/{quotationId}/{telephoneNumberId}/mobile-settings',
        toggleFlag: 'akb/{quotationId}/toggleFlag',
        updateAKBSelections: 'akb/update',
        patchAllTelephoneNumbers: 'akb/allNumbers/patch/{quotationId}',
        updateVAMOForTelephoneNumbers: 'akb/allNumbersVAMO/patch/{quotationId}',
        updateDataSimAmountForTelephoneNumbers: 'akb/allNumbersDataSimAmount/patch/{quotationId}',
    },
    BulkImport: {
        bulkImportPhoneNumbers: 'bulkUpload/{quotationId}/{locationId}/{existing}/upload/{fileType}',
    },
    ordersRelated:  {
        getAll: 'ordersList',
        getQuotationOrderProducts: 'quotation-order-products/{quotation}',
        updateOrderSentProduct: 'quotation-order-product/{order}',
        resendOrder: 'quotation-order-product/{order}/resend-order',
        updateVamoOrder: 'quotation-order-product/{order}/update-vamo-order-details',
        updateAccessOrder: 'quotation-order-product/{order}/update-access-order-details',
        updateUsernameOrder: 'quotation-order-product/{order}/update-username-order-details',
        updateVoiceNumberOrder: 'quotation-order-product/{order}/update-voice-number-order-details',
        updateTelephoneNumberOrder: 'quotation-order-product/{order}/update-telephone-number-order-details',
    },
    orderWizardRelated: {
        getOrderWizardDetailOptions: 'order-wizard/detail-options',
        getOrderWizardDetails: 'order-wizard/{quotationID}/details',
        updateOrderWizardDetails: 'order-wizard/{orderDetailsID}/update-details',
        getOrderAddressList: 'order-wizard/{customerOrderDetails}/address-list',
        addOrderAddress: 'order-wizard/{customerOrderDetails}/add-address',
        updateOrderAddress: 'order-wizard/{orderAddress}/update-address',
        removeOrderAddress: 'order-wizard/{orderAddress}/remove-address',
    },
    apiStatusRelated: {
        getAll: 'apiStatus/{quotationId}'
    },
    dataManagementRelated: {
        getProducts: 'data-management/{organizationID}/{provider}/product-list',
        computeDependencies: 'data-management/{organizationID}/{provider}/compute-dependencies',
    },
    connectWiseRelated: {
        syncCwCustomer: 'customer/{customer}/cwsync',
        createCwOpportunity: 'sales/opportunities',
        getOpportunitiesByCompanyRecID: 'sales/opportunities/{customer}',
        setSelectedQuotationForOpportunity: 'sales/opportunities/set-quotation/{quotation}'
    },
    adminOrders: {
        getAll: 'admin/ordersList',
        getQuotationAdminOrderProducts: 'admin/quotation-order-products/{quotation}',
        changeAdminOrderStatus: 'admin/order/status/{id}'
    },
    messages: {
        getMessages: 'messages/all/{quotation}',
        sendMessage: 'messages/send/{quotation}'
    },
    emailTemplates: {
        getEmailTemplates: 'email-templates',
        updateEmailTemplate: 'email-templates/{emailTemplate}'
    }
};

export {apiRoutes};
